<script lang="ts">
import { defineComponent} from 'vue'
import { useRoute } from 'devcude-common/src/utils/DcRouter.ts'

export default defineComponent({
  name: 'Rt',
  setup(){
    const route = useRoute();
    console.log(route)
  }
})
</script>

<template>

</template>

<style scoped>

</style>