import type { RouteRecordRaw } from 'vue-router'

const routers: RouteRecordRaw = {
  path: '/shp-init-setting',
  name: 'ShpInitSetting',
  meta: {
    title: '商城初始配置'
  },
  children: [
    {
      path: 'list',
      name: 'ShpInitSettingList',
      meta: {
        title: '商城初始列表'
      },
      component: () => {
        return import('@/views/admin/shp-init-setting/ShpInitSettingList.vue')
      }
    },
    {
      path: 'add',
      name: 'ShpInitSettingAdd',
      meta: {
        title: '商城初始配置新增'
      },
      component: () => {
        return import('@/views/admin/shp-init-setting/ShpInitSettingAdd.vue')
      }
    },
    {
      path: 'edit',
      name: 'ShpInitSettingEdit',
      meta: {
        title: '商城初始配置编辑'
      },
      component: () => {
        return import('@/views/admin/shp-init-setting/ShpInitSettingEdit.vue')
      }
    }
  ]
}
export default routers
