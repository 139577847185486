import type { RouteRecordRaw } from 'vue-router'

const entity = 'DC_DATA_ENTITY'
const routers: RouteRecordRaw = {
  path: '/data-entity',
  name: 'data-entity',
  meta: {
    title: '数据实体管理'
  },
  children: [
    {
      path: 'list',
      name: 'data-entity-list',
      meta: {
        title: '实体列表'
      },
      props: {
        entity: entity
      },
      component: () => import('../../views/dynamic-form/DynamicListView.vue')
    },
    {
      path: 'design',
      name: 'data-entity-design',
      meta: {
        title: '实体设计'
      },
      component: () => import('../../views/data-entity-designer/DataEntityDesign.vue')
    }

  ]
}
export default routers
