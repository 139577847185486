import type { RouteRecordRaw } from 'vue-router'
const routers: RouteRecordRaw = {
  path: '/shp-sys-user',
  name:'ShpSysUser',
  meta: {
    title: '系统账号'
  },
  children: [
    {
      path: 'list',
      name: 'ShpSysUserList',
      meta: {
        title: '系统账号列表'
      },
      component: ()=>{return import("@/views/shp-sys-user/ShpSysUserList.vue")}
    },
    {
      path: 'add',
      name: 'ShpSysUserAdd',
      meta: {
        title: '系统账号新增'
      },
      component: ()=>{return import("@/views/shp-sys-user/ShpSysUserAdd.vue")}
    },
    {
      path: 'edit',
      name: 'ShpSysUserEdit',
      meta: {
        title: '系统账号编辑'
      },
      component: ()=>{return import("@/views/shp-sys-user/ShpSysUserEdit.vue")}
    }
  ]
}
export default routers
