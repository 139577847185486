<script setup lang="ts">
import { ref } from 'vue'
import qs from 'qs'
import ShpProdCardList from '@/components/shp-prod-choose-box/ShpProdCardList.vue'
import ShpProdSkuChooseBox from '@/components/shp-prod-choose-box/ShpProdSkuChooseBox.vue'
import AmountText from '@/components/AmountText.vue'
import { DcUtils } from 'devcude-common/src/utils/DcUtils'
import ProdItemDescList from '@/components/ProdItemDescList.vue'

const emit = defineEmits(['skuConfirm'])
const props = defineProps({
  mode: {
    type: String,
    default: 'orderItem'
  },
  shpAgentId: {
    type: String,
    default: () => {
      return ''
    }
  }
})
const dialogVisible = ref(false)
const pager = ref({
  pageIndex: 1,
  pageSize: 10,
  itemCount: 0
})
const skuChooseBox = ref(null)
const orderItems = defineModel([])

async function handleProdClick(item) {
  skuChooseBox.value.handleShow(item.id, (orderItem) => {
    if (['orderItem', 'PKG_ITEM'].indexOf(props.mode) != -1) {
      orderItems.value.push(orderItem)
    }

    if (props.mode === 'sku') {
      emit('skuConfirm', orderItem)
      close()
    }
  })
}

async function handleChooseOrderItem(item, index) {
  skuChooseBox.value.handleShowOrderItem(JSON.parse(JSON.stringify(item)), (orderItem) => {
    orderItems.value[index] = orderItem
  })
}

function qsParse(qsString) {
  return DcUtils.qsParse(qsString)
}

async function show() {
  dialogVisible.value = true
}

async function close() {
  dialogVisible.value = false
}

defineExpose({ show })
</script>

<template>
  <el-dialog
    append-to-body
    v-model="dialogVisible"
    :width="['orderItem', 'PKG_ITEM'].indexOf(mode) != -1 ? '1240px' : '872px'"
    class="!p-0 shp-prod-choose-box"
    :show-close="false"
  >
    <template #header></template>
    <div class="flex justify-between overflow-hidden h-[838px]">
      <div
        v-if="['orderItem', 'PKG_ITEM'].indexOf(mode) != -1"
        class="border-r w-[368px] flex flex-col"
      >
        <div class="flex justify-between h-[56px] items-center border-b pl-[12px] pr-[12px]">
          <div>
            <el-text>已选列表</el-text>
          </div>
          <div>
            <el-text class="cursor-pointer" @click="orderItems = []">清空</el-text>
          </div>
        </div>
        <div class="flex-1 overflow-hidden">
          <el-empty
            class="h-full"
            v-if="!orderItems?.length"
            description="请从右边挑选商品吧！"
          ></el-empty>

          <el-scrollbar class="h-full">
            <div class="p-[12px]">
              <div
                v-for="(orderItem, index) in orderItems"
                :key="index"
                @click="handleChooseOrderItem(orderItem, index)"
                class="order-item mb-[12px] cursor-pointer"
              >
                <div class="bg-[#E6E8EB] flex justify-between p-[12px] text-[#606266]">
                  <div class="flex-1">
                    <el-text size="default">{{ orderItem.name }}</el-text>
                  </div>



                  <div class="w-[36px] text-right">
                    <el-text
                      type="danger"
                      @click.stop="orderItems.splice(orderItems.indexOf(orderItem), 1)"
                      class="cursor-pointer"
                      size="small"
                    >
                      删除
                    </el-text>
                  </div>
                </div>
                <div class="border p-[12px]">
                  <div>
                    <template v-for="(item, i) in qsParse(orderItem.skuCombo)" :key="i">
                      <span class="mr-[12px]">
                        <el-text size="small"> {{ item.name }} : {{ item.value }} </el-text>
                      </span>
                    </template>
                  </div>
                  <div>
                    <div>
                      <div>
                        <el-text size="small">套餐必选</el-text>
                      </div>
                      <ProdItemDescList v-model="orderItem.pkgMustItems"></ProdItemDescList>
                    </div>
                    <div v-for="(group, i) in orderItem.pkgCustItems" :key="i">
                      <div>
                        <el-text size="small">{{ group.name }}({{ group.requiredQty }})</el-text>
                      </div>
                      <ProdItemDescList
                        :model-value="group.items.filter((e) => e.choosed)"
                      ></ProdItemDescList>
                    </div>
                    <div>
                      <el-text size="small">
                        备注:{{ orderItem.remarks ? orderItem.remarks : '(无)' }}
                      </el-text>
                    </div>
                  </div>
                  <div class="flex flex-row-reverse mt-[24px]">
                    <el-text type="warning" size="small">
                       ( {{parseFloat(orderItem.price).toFixed(2)}}(单价) + {{parseFloat(orderItem.extraPrice).toFixed(2)}}(加价) ) * {{parseFloat(orderItem.itemQty).toFixed(2)}}(数量)
                    </el-text>
                  </div>
                  <div class="flex flex-row-reverse mt-[12px]">

                    <el-text type="warning" size="default">
                      小计: <amount-text type="warning" size="default" :amount="(parseFloat(orderItem.price) + parseFloat(orderItem.extraPrice)) * orderItem.itemQty"></amount-text>
                    </el-text>

                  </div>
                </div>
              </div>
            </div>
          </el-scrollbar>
        </div>
        <div class="flex flex-row-reverse justify-center p-[12px] border-t">
          <el-button @click="close" size="default">取消</el-button>
          <el-button @click="close" class="mr-[12px]" size="default" type="primary">确认</el-button>
        </div>
      </div>
      <ShpProdCardList
        :shpAgentId="shpAgentId"
        class="w-[872px]"
        @item-click="handleProdClick"
      ></ShpProdCardList>
      <ShpProdSkuChooseBox
        :shpAgentId="shpAgentId"
        :mode="mode"
        ref="skuChooseBox"
      ></ShpProdSkuChooseBox>
    </div>
  </el-dialog>
</template>

<style>
.shp-prod-choose-box .el-dialog__header {
  display: none;
}
</style>
