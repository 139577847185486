<script setup lang="ts">
import { nextTick, ref } from 'vue'

import printJs from 'print-js'
import qs from 'qs'

import $api from '../config/api'
import $http from 'devcude-common/src/utils/DcHttp'
import PurchaseReportPrintTpl from '@/components/PurchaseReportPrintTpl.vue'
import PurchaseReportPrintSimpleTpl from '@/components/PurchaseReportPrintSimpleTpl.vue'
import { ElMessage } from 'element-plus'

const v = ref(false)
const loading = ref(false)
const emit = defineEmits(['finish'])
const print = ref(false)
const printData = ref([])
const ctx = ref();
async function show(data) {
  printData.value = data
  v.value = true
}

function handlePrint() {
  print.value = true
  nextTick(() => {
    printJs({
      printable: 'print-ctx',
      type: 'html',
      // header:"二维码打印",
      targetStyles: ['*'],
      repeatTableHeader: true,
      style: '@page {margin:2.4cm 2cm ;resolution: 300dpi;}',
      //解决字体样式失效的问题
      font_size: '',
      onLoadingStart: () => {
        console.log('onLoadingStart')
      },
      onLoadingEnd: async () => {
        console.log('onLoadingEnd')

        let ids = printItems.value.map((e) => e.id)
        await $http.post(
          $api.shpDeliveryOrderRef.updatePrintTimes,
          qs.stringify({ ids: ids.join(',') })
        )
        v.value = false
        emit('finish')
      },
      onPrintDialogClose: () => {
        print.value = false
      }
    })
  })
}

function handleCopy(text) {

  // 使用 Clipboard API 複製文本
  navigator.clipboard.writeText(document.querySelector('#print-ctx').innerText)
    .then(() => {
      ElMessage.success("複製成功")
    })
    .catch(err => {
      ElMessage.error("複製失敗")
    });
}

defineExpose({ show })
</script>

<template>
  <el-dialog v-model="v" @close="handleClose" width="800px">
    <el-divider></el-divider>
    <el-scrollbar v-loading="loading" height="800px">
        <purchase-report-print-simple-tpl  :data="printData" id="print-ctx" tpl="rrs"></purchase-report-print-simple-tpl>
    </el-scrollbar>
    <el-divider></el-divider>
    <div class="flex justify-center">
      <el-button @click="handlePrint()" type="primary">打印</el-button>
      <el-button @click="handleCopy()" type="warning">複製</el-button>
      <el-button @click="v=false">取消</el-button>
    </div>
  </el-dialog>
</template>

<style></style>
