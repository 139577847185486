<script setup lang="ts">
import DcDictValueText from 'dc-comp-sdk/src/components/form/DcDictValueText.vue'
import DcDateFormat from 'dc-comp-sdk/src/components/form/DcDateFormat.vue'
import { onMounted, ref, watch } from 'vue'

const tableInst = ref()
const items = defineModel([])
const props = defineProps({ showSelection: true })

function test111(row, c) {
  console.log("row",row)
  tableInst.value.toggleRowSelection(row, c)
}

defineExpose({ test111 })


</script>

<template>
  <el-table ref="tableInst" :data="items" row-key="id" max-height="500px" border>
    <el-table-column v-if="showSelection" type="selection" :reserve-selection="true"></el-table-column>
    <el-table-column width="140px" label="订单编号" prop="number"></el-table-column>
    <el-table-column label="订单类型">
      <template #default="scope">
        <DcDictValueText v-model="scope.row.type" dict-type="DCSHP_ORDER_TYPE"></DcDictValueText>
      </template>
    </el-table-column>
    <el-table-column label="订单金额" prop="amount"></el-table-column>
    <el-table-column label="渠道销售金额" prop="agentAmount"></el-table-column>
    <el-table-column label="渠道结算金额" prop="agentSettleAmount"></el-table-column>
    <el-table-column label="结算状态">
      <template #default="scope">
        <DcDictValueText v-model="scope.row.shpAgentSettleStatus"
                         dict-type="DCSHP_AGENT_SETTLE_RECORD_STATUS"></DcDictValueText>
      </template>
    </el-table-column>
    <el-table-column label="下单时间">
      <template #default="scope">
        <DcDateFormat v-model="scope.row.createTime"></DcDateFormat>
      </template>
    </el-table-column>
    <el-table-column width="140px" label="操作" align="right">
      <template #default="scope">
        <slot name="op-col" :row="scope.row">
          <el-link type="danger" @click="$router.push({path:'detail',query:{id:scope.row.id}})" :underline="false"
                   class="mr-[12px]">详情
          </el-link>
        </slot>
      </template>
    </el-table-column>
  </el-table>
</template>

<style scoped>

</style>