<script setup lang="ts">
import { nextTick, ref } from 'vue'
import ShpOrderList from '@/views/shp-order/ShpOrderList.vue'
import { useRouter } from 'vue-router'

const router =useRouter()
const v = ref(false)
const pId = ref(-1);
async function show({purchaseIitemId}) {
  pId.value =purchaseIitemId
  v.value = true
}

function handleDetailClick(e){
  window.open('/admin/shp-order/detail?id='+e.id)
}

function handleClose(){
  v.value = false;
  pId.value = -1;
}


defineExpose({ show })
</script>

<template>
  <el-dialog v-model="v" @close="handleClose" width="1200px" >
    <ShpOrderList v-if="v" :show-search-form="false" :show-btn-group="false" :on-page="false" :purchase-iitem-id="pId" :read-only="true" :enable-dynamic-query="false" :handle-detail-click="handleDetailClick"></ShpOrderList>
  </el-dialog>
</template>

<style></style>
