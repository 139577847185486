//域名
const domain = window.location.host + '/api'

//端口
const port = null

//路径
const root = window.location.protocol + '//' + domain + (port ? (':' + port) : '')
//接口
const api = {
  shpProdCat: {
    add: `${root}/shpProdCat/add`,
    update: `${root}/shpProdCat/update`,
    detail: `${root}/shpProdCat/detail`,
    list: `${root}/shpProdCat/list`,
    tree: `${root}/shpProdCat/tree`,
    delete: `${root}/shpProdCat/delete`,
    batchDelete: `${root}/shpProdCat/batchDelete`
  },
  shpUnit: {
    add: `${root}/shpUnit/add`,
    update: `${root}/shpUnit/update`,
    detail: `${root}/shpUnit/detail`,
    list: `${root}/shpUnit/list`,
    delete: `${root}/shpUnit/delete`,
    batchDelete: `${root}/shpUnit/batchDelete`
  },
  shpProdLabel: {
    add: `${root}/shpProdLabel/add`,
    update: `${root}/shpProdLabel/update`,
    detail: `${root}/shpProdLabel/detail`,
    list: `${root}/shpProdLabel/list`,
    delete: `${root}/shpProdLabel/delete`,
    batchDelete: `${root}/shpProdLabel/batchDelete`
  },
  shpVipLevel: {
    add: `${root}/shpVipLevel/add`,
    update: `${root}/shpVipLevel/update`,
    detail: `${root}/shpVipLevel/detail`,
    list: `${root}/shpVipLevel/list`,
    delete: `${root}/shpVipLevel/delete`,
    batchDelete: `${root}/shpVipLevel/batchDelete`
  },
  shpVipDiscount: {
    add: `${root}/shpVipDiscount/add`,
    update: `${root}/shpVipDiscount/update`,
    detail: `${root}/shpVipDiscount/detail`,
    list: `${root}/shpVipDiscount/list`,
    delete: `${root}/shpVipDiscount/delete`,
    batchDelete: `${root}/shpVipDiscount/batchDelete`
  },
  shpSupplier: {
    add: `${root}/shpSupplier/add`,
    update: `${root}/shpSupplier/update`,
    detail: `${root}/shpSupplier/detail`,
    list: `${root}/shpSupplier/list`,
    delete: `${root}/shpSupplier/delete`,
    batchDelete: `${root}/shpSupplier/batchDelete`
  },
  shpAgent: {
    add: `${root}/shpAgent/add`,
    update: `${root}/shpAgent/update`,
    detail: `${root}/shpAgent/detail`,
    list: `${root}/shpAgent/list`,
    delete: `${root}/shpAgent/delete`,
    batchDelete: `${root}/shpAgent/batchDelete`
  },
  shpBanner: {
    add: `${root}/shpBanner/add`,
    update: `${root}/shpBanner/update`,
    detail: `${root}/shpBanner/detail`,
    list: `${root}/shpBanner/list`,
    delete: `${root}/shpBanner/delete`,
    batchDelete: `${root}/shpBanner/batchDelete`
  },
  shpArticle: {
    add: `${root}/shpArticle/add`,
    update: `${root}/shpArticle/update`,
    detail: `${root}/shpArticle/detail`,
    list: `${root}/shpArticle/list`,
    delete: `${root}/shpArticle/delete`,
    batchDelete: `${root}/shpArticle/batchDelete`
  },
  shpSetting: {
    save: `${root}/shpSetting/save`,
    detail: `${root}/shpSetting/detail`,
    list: `${root}/shpSetting/list`
  },
  shpProduct: {
    add: `${root}/shpProduct/add`,
    update: `${root}/shpProduct/update`,
    detail: `${root}/shpProduct/detail`,
    list: `${root}/shpProduct/list`,
    delete: `${root}/shpProduct/delete`,
    changeStatus: `${root}/shpProduct/changeStatus`,
    batchDelete: `${root}/shpProduct/batchDelete`
  },
  shpOrder: {
    add: `${root}/shpOrder/add`,
    updateShipDate: `${root}/shpOrder/updateShipDate`,
    updateRemarks: `${root}/shpOrder/updateRemarks`,
    update: `${root}/shpOrder/update`,
    detail: `${root}/shpOrder/detail`,
    list: `${root}/shpOrder/list`,
    export: `${root}/shpOrder/export`,
    delete: `${root}/shpOrder/delete`,
    cancel: `${root}/shpOrder/cancel`,
    orderFeeInfo: `${root}/shpOrder/orderFeeInfo`,
    confirm: `${root}/shpOrder/confirm`,
    updateDealStatus: `${root}/shpOrder/updateDealStatus`,
    batchUpdateDealStatus: `${root}/shpOrder/batchUpdateDealStatus`,
    waitDealOrderCount: `${root}/shpOrder/waitDealOrderCount`,
    batchDelete: `${root}/shpOrder/batchDelete`
  },
  bogoProduct: {
    add: `${root}/bogoProduct/add`,
    update: `${root}/bogoProduct/update`,
    detail: `${root}/bogoProduct/detail`,
    list: `${root}/bogoProduct/list`,
    delete: `${root}/bogoProduct/delete`,
    configAmount: `${root}/bogoProduct/configAmount`,
    batchDelete: `${root}/bogoProduct/batchDelete`
  },
  bundleProduct: {
    add: `${root}/bundleProduct/add`,
    update: `${root}/bundleProduct/update`,
    detail: `${root}/bundleProduct/detail`,
    list: `${root}/bundleProduct/list`,
    delete: `${root}/bundleProduct/delete`,
    batchDelete: `${root}/bundleProduct/batchDelete`
  },
  shpUser: {
    add: `${root}/shpUser/add`,
    update: `${root}/shpUser/update`,
    detail: `${root}/shpUser/detail`,
    list: `${root}/shpUser/list`,
    delete: `${root}/shpUser/delete`,
    defaultAddress: `${root}/shpUser/defaultAddress`,
    batchDelete: `${root}/shpUser/batchDelete`
  },
  shpFeedback: {
    add: `${root}/shpFeedback/add`,
    update: `${root}/shpFeedback/update`,
    detail: `${root}/shpFeedback/detail`,
    list: `${root}/shpFeedback/list`,
    delete: `${root}/shpFeedback/delete`,
    batchDelete: `${root}/shpFeedback/batchDelete`
  },
  shpOrderPayRecord: {
    add: `${root}/shpOrderPayRecord/add`,
    updatePayStatus: `${root}/shpOrderPayRecord/updatePayStatus`,
    update: `${root}/shpOrderPayRecord/update`,
    detail: `${root}/shpOrderPayRecord/detail`,
    list: `${root}/shpOrderPayRecord/list`,
    delete: `${root}/shpOrderPayRecord/delete`,
    batchDelete: `${root}/shpOrderPayRecord/batchDelete`
  },
  shpOrderRefund: {
    add: `${root}/shpOrderRefund/add`,
    update: `${root}/shpOrderRefund/update`,
    detail: `${root}/shpOrderRefund/detail`,
    list: `${root}/shpOrderRefund/list`,
    delete: `${root}/shpOrderRefund/delete`,
    batchDelete: `${root}/shpOrderRefund/batchDelete`
  },
  dcShop: {
    add: `${root}/dcShop/add`,
    update: `${root}/dcShop/update`,
    detail: `${root}/dcShop/detail`,
    list: `${root}/dcShop/list`,
    delete: `${root}/dcShop/delete`,
    batchDelete: `${root}/dcShop/batchDelete`
  },
  shpSysUser: {
    add: `${root}/shpSysUser/add`,
    update: `${root}/shpSysUser/update`,
    detail: `${root}/shpSysUser/detail`,
    list: `${root}/shpSysUser/list`,
    delete: `${root}/shpSysUser/delete`,
    batchDelete: `${root}/shpSysUser/batchDelete`
  },
  shpAdminAuth: {
    login: `${root}/shpAdminAuth/login`,
    changeSystem: `${root}/shpAdminAuth/changeSystem`,
    userinfo: `${root}/shpAdminAuth/userinfo`,
    userSystems: `${root}/shpAdminAuth/userSystems`,
    logout: `${root}/shpAdminAuth/logout`
  },
  shpAgentPrice: {
    agentProducts: `${root}/shpProdAgentPrice/agentProducts`,
    agentProductInfo: `${root}/shpProdAgentPrice/agentProductInfo`,
    settingAgentProdPrice: `${root}/shpProdAgentPrice/settingAgentProdPrice`
  },
  shpAgentSettleRecord: {
    add: `${root}/shpAgentSettleRecord/add`,
    cancel: `${root}/shpAgentSettleRecord/cancel`,
    confirm: `${root}/shpAgentSettleRecord/confirm`,
    update: `${root}/shpAgentSettleRecord/update`,
    detail: `${root}/shpAgentSettleRecord/detail`,
    list: `${root}/shpAgentSettleRecord/list`,
    delete: `${root}/shpAgentSettleRecord/delete`,
    batchDelete: `${root}/shpAgentSettleRecord/batchDelete`
  },
  purchaseReport: {
    add: `${root}/purchaseReport/add`,
    update: `${root}/purchaseReport/update`,
    detail: `${root}/purchaseReport/detail`,
    list: `${root}/purchaseReport/list`,
    delete: `${root}/purchaseReport/delete`,
    listShpSuppliers: `${root}/purchaseReport/listShpSuppliers`,
    batchDelete: `${root}/purchaseReport/batchDelete`
  },
  purchaseItem: {
    add: `${root}/purchaseItem/add`,
    update: `${root}/purchaseItem/update`,
    detail: `${root}/purchaseItem/detail`,
    list: `${root}/purchaseItem/list`,
    delete: `${root}/purchaseItem/delete`,
    batchDelete: `${root}/purchaseItem/batchDelete`
  },
  shpProdComment: {
    add: `${root}/shpProdComment/add`,
    update: `${root}/shpProdComment/update`,
    detail: `${root}/shpProdComment/detail`,
    list: `${root}/shpProdComment/list`,
    delete: `${root}/shpProdComment/delete`,
    batchDelete: `${root}/shpProdComment/batchDelete`
  },
  shpInitSetting: {
    add: `${root}/shpInitSetting/add`,
    update: `${root}/shpInitSetting/update`,
    detail: `${root}/shpInitSetting/detail`,
    list: `${root}/shpInitSetting/list`,
    delete: `${root}/shpInitSetting/delete`,
    batchDelete: `${root}/shpInitSetting/batchDelete`
  },
  shpCommon:{
    dcShopHomeData:`${root}/shpCommon/dcShopHomeData`,
    productSaleRange:`${root}/shpCommon/productSaleRange`,
    saleData:`${root}/shpCommon/saleData`,
    recognition:`${root}/shpCommon/recognition`,
  },
  shpDeliveryOrderPrint:{
    add: `${root}/shpDeliveryOrderPrint/add`,
    update: `${root}/shpDeliveryOrderPrint/update`,
    detail: `${root}/shpDeliveryOrderPrint/detail`,
    list: `${root}/shpDeliveryOrderPrint/list`,
    delete: `${root}/shpDeliveryOrderPrint/delete`,
    batchDelete: `${root}/shpDeliveryOrderPrint/batchDelete`,
    syncDeliveryOrderRef: `${root}/shpDeliveryOrderPrint/syncDeliveryOrderRef`,

  },
  shpDeliveryOrderRef:{
    add: `${root}/shpDeliveryOrderRef/add`,
    update: `${root}/shpDeliveryOrderRef/update`,
    detail: `${root}/shpDeliveryOrderRef/detail`,
    list: `${root}/shpDeliveryOrderRef/list`,
    export: `${root}/shpDeliveryOrderRef/export`,
    delete: `${root}/shpDeliveryOrderRef/delete`,
    updatePrintTimes: `${root}/shpDeliveryOrderRef/updatePrintTimes`,
    batchDelete: `${root}/shpDeliveryOrderRef/batchDelete`
  }
}

window['LOGIN_URL'] = api.shpAdminAuth.login

let $api = Object.assign({
  $base: {
    domain: domain, port: port, root: root
  }
}, api)
export default $api
