<script setup lang="ts">
import DcCommonContent from 'devcude-ui/src/components/layout/DcCommonContent.vue'
import $api from '../../config/api'
import $http from 'devcude-common/src/utils/DcHttp'
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import _ from 'lodash'
import qs from 'qs'
import { ElMessage, ElMessageBox } from 'element-plus'
import { DcUtils } from 'devcude-common/src/utils/DcUtils'
import { useRoute } from 'vue-router'
import DcDictValueText from 'dc-comp-sdk/src/components/form/DcDictValueText.vue'
import DcSinglePicShow from 'dc-comp-sdk/src/components/form/DcSinglePicShow.vue'
import DcDictSelect from 'dc-comp-sdk/src/components/form/DcDictSelect.vue'
import DcDateFormat from 'dc-comp-sdk/src/components/form/DcDateFormat.vue'
import AmountText from '@/components/AmountText.vue'

const route = useRoute()
const props = defineProps({
  showSearchForm: {
    type: Boolean,
    default: true
  },
  showBtnGroup: {
    type: Boolean,
    default: true
  },
  onPage: {
    type: Boolean,
    default: true
  },
  purchaseIitemId: {
    type: String,
    default: ''
  },
  readOnly:{
    type: Boolean,
    default: false
  },
  handleDetailClick:{
    type: Function,
    default: null
  },
  enableDynamicQuery:{
    type: Boolean,
    default: true
  },
})
let pagerKey = 'pager' + '_' + route.fullPath
let pagerJson = sessionStorage.getItem(pagerKey)
const pager = ref(
  pagerJson
    ? JSON.parse(pagerJson)
    : {
        pageIndex: 1,
        pageSize: 10,
        itemCount: 0,
        onPage: props.onPage
      }
)

watch(
  pager,
  () => {
    sessionStorage.setItem(pagerKey, JSON.stringify(pager.value))
  },
  { deep: true }
)

const items = ref([])
const selection = ref([])
const LIST_URL = $api.shpOrder.list
const DELETE_URL = $api.shpOrder.delete
const BATCH_DELETE_URL = $api.shpOrder.batchDelete
const searchQueryCond = {
  number: 'LIKE'
}

let searchQueryKey = 'searchQuery' + '_' + route.fullPath
let searchQueryJson = sessionStorage.getItem(searchQueryKey)

// 检索参数开始
const searchQuery = {
  number: '',
  shipType: '',
  recipient: '',
  recipientPhone: '',
  status: '',
  createType:''
}

const sqy = ref(searchQueryJson ? JSON.parse(searchQueryJson) : Object.assign(searchQuery))
const orderTime = ref([])
const shipDate = ref([])

const params = ref({
  shipDateStart: '',
  shipDateEnd: '',
  createTimeStart: '',
  createTimeEnd: '',
  numberSort: '',
  purchaseIitemId:props.purchaseIitemId

})

watch(orderTime, () => {
  if (orderTime.value?.length) {
    params.value.createTimeStart = orderTime.value[0]
    params.value.createTimeEnd = orderTime.value[1]
  } else {
    params.value.createTimeStart = ''
    params.value.createTimeEnd = ''
  }
})

watch(shipDate, () => {
  if (shipDate.value?.length) {
    params.value.shipDateStart = shipDate.value[0]
    params.value.shipDateEnd = shipDate.value[1]
  } else {
    params.value.shipDateStart = ''
    params.value.shipDateEnd = ''
  }
})

watch(
  sqy,
  () => {
    sessionStorage.setItem(searchQueryKey, JSON.stringify(sqy.value))
  },
  { deep: true }
)
const dynamicQuery = computed(() => {
  let p = {}
  for (let key in sqy.value) {
    if (sqy.value[key]) {
      if (searchQueryCond[key]) {
        p[`${key}@${searchQueryCond[key]}`] = sqy.value[key]
      } else {
        p[`${key}`] = sqy.value[key]
      }
    }
  }
  return p
})

async function handleSearch(){
  pager.value.pageIndex = 1
  handleLoadData()
}

async function handleLoadData() {
  let query = {}
  let resp = await $http.post(
    LIST_URL,
    qs.stringify(
      Object.assign(query, params.value, pager.value, {
        conds: props.enableDynamicQuery ? DcUtils.param2DynamicCondQuery(dynamicQuery.value) : ''
      })
    )
  )
  items.value = resp.data.items
  _.assign(pager.value, _.pick(resp.data.pager, _.keys(pager.value)))
}

async function handleSelectionChange(e: any) {
  selection.value = e
}

async function handleDelete(row) {
  ElMessageBox.confirm('你现在正在进行删除操作，是否继续?')
    .then(async () => {
      await $http.post(DELETE_URL, qs.stringify({ id: row.id }), { showTips: true })
      await handleLoadData()
    })
    .catch(() => {
      // catch error
    })
}

async function handleExport() {
  let query = qs.stringify(
    Object.assign({}, pager.value, params.value, {
      conds: DcUtils.param2DynamicCondQuery(dynamicQuery.value)
    })
  )
  window.open($api.shpOrder.export + '?' + query)
}

async function handleRestSearch() {
  sqy.value = Object.assign({}, searchQuery)
  orderTime.value = []
  shipDate.value = []
  pager.value.pageIndex = 1
  nextTick(()=>{
    handleLoadData()
  })
}

async function handleBatchDelete() {
  if (!selection.value.length) {
    ElMessage.warning('您未选中记录')
    return
  }

  ElMessageBox.confirm('你现在正在进行批量删除操作，是否继续?')
    .then(async () => {
      let ids = ''
      selection.value.forEach((item) => {
        if (ids) {
          ids += `,${item['id']}`
        } else {
          ids = item['id']
        }
      })
      await $http.post(BATCH_DELETE_URL, qs.stringify({ ids }), { showTips: true })
      await handleLoadData()
    })
    .catch(() => {
      // catch error
    })
}

const orderItems = computed(() => {
  let orderItems = []
  items.value.forEach((e) => {
    e.orderItems.forEach((o) => {
      o.order = e
      orderItems.push(o)
    })
  })

  return orderItems
})

function arraySpanMethod(row, rowSpan) {
  if (
    [
      '選擇',
      '订单编号',
      '配送方式',
      '买家信息',
      '订单金额',
      '状态',
      '操作',
      '配送信息',
      '下单时间',
      '订单类型'
    ].indexOf(row.column.label) != -1
  ) {
    let index = row.row.order.orderItems.indexOf(row.row)

    if (index == 0) {
      return { rowspan: row.row.order.orderItems.length, colspan: 1 }
    }

    if (index > 0) {
      return { rowspan: 0, colspan: 1 }
    }
  }

  return { rowspan: 1, colspan: 1 }
}

function handleTableSortChange(order) {
  if ('order.number' === order.prop) {
    if (order.order) {
      if ('descending' === order.order) {
        params.value.numberSort = 'desc'
      }

      if ('ascending' === order.order) {
        params.value.numberSort = 'asc'
      }
    } else {
      params.value.numberSort = ''
    }
    pager.value.length = 1;
    handleLoadData()
  }
}

function handleConfirm(row) {
  ElMessageBox.confirm('你现在正在进行订单确认操作，是否继续?')
    .then(async () => {
      console.log('dddd')
      await $http.post($api.shpOrder.confirm, qs.stringify({ id: row.id }), { showTips: true })
      await handleLoadData()
    })
    .catch(() => {
      // catch error
    })
}

function qsParse(qsString) {
  return DcUtils.qsParse(qsString)
}

onMounted(() => {
  handleLoadData()
})
</script>

<template>
  <DcCommonContent>
    <div class="w-full h-full flex flex-col overflow-hidden">

      <template v-if="showBtnGroup">
        <div class="mb-[12px] flex justify-between">
          <el-button-group>
            <el-button @click="$router.push({ path: 'add' })" icon="plus">新增</el-button>
            <el-button @click="handleBatchDelete()" icon="delete">删除</el-button>
          </el-button-group>

          <div class="flex flex-1 flex-row-reverse">
            <el-button-group>
              <el-button @click="handleExport()" icon="download">導出</el-button>
            </el-button-group>
          </div>
        </div>
        <el-divider class="!mt-0 !mb-[24px]" />
      </template>



      <el-form v-if="showSearchForm" :inline="true" class="mb-[6px] search-form">
        <el-form-item label="订单编号">
          <el-input v-model="sqy.number" placeholder="订单编号" clearable />
        </el-form-item>

        <el-form-item label="收货人">
          <el-input v-model="sqy.recipient" placeholder="收货人" clearable />
        </el-form-item>

        <el-form-item label="收货人电话">
          <el-input v-model="sqy.recipientPhone" placeholder="收货人电话" clearable />
        </el-form-item>

        <el-form-item label="送货时间">
          <el-date-picker
            v-model="shipDate"
            value-format="YYYY-MM-DD"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="配送方式">
          <DcDictSelect v-model="sqy.shipType" dict-type="DCSHP_SHIP_TYPE" clearable></DcDictSelect>
        </el-form-item>
        <el-form-item label="下单时间">
          <el-date-picker
            v-model="orderTime"
            value-format="YYYY-MM-DD"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="下单方式">
          <DcDictSelect
            v-model="sqy.createType"
            dict-type="DCSHP_ORDER_CREATE_TYPE"
            clearable
          ></DcDictSelect>
        </el-form-item>

        <el-form-item label="状态">
          <DcDictSelect
            v-model="sqy.status"
            dict-type="DCSHP_ORDER_STATUS"
            clearable
          ></DcDictSelect>
        </el-form-item>



        <el-form-item>
          <el-button @click="handleSearch" type="primary">查询</el-button>
          <el-button @click="handleRestSearch">重置</el-button>
        </el-form-item>
      </el-form>

      <el-table
        :data="orderItems"
        @selectionChange="handleSelectionChange"
        @sort-change="handleTableSortChange"
        :span-method="arraySpanMethod"
        :row-key="
          (row: any) => {
            return row.id
          }
        "
        :max-height="onPage ? 'auto' : '800px'"
        border
      >
        <el-table-column type="selection" label="選擇" :reserve-selection="true" fixed></el-table-column>
        <el-table-column
          width="180px"
          label="订单编号"
          prop="order.number"
          sortable="custom"
          fixed="left"
        ></el-table-column>
        <el-table-column label="商品信息" min-width="280px">
          <template #default="scope">
            <div class="flex items-center min-w-[200px]">
              <DcSinglePicShow
                class="mr-[12px] border"
                width="32px"
                height="32px"
                :model-value="scope.row.pic ? scope.row.pic.split(',')[0] : ''"
              ></DcSinglePicShow>
              <span>{{ scope.row.name }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="150px" label="规格">
          <template #default="scope">
            <template v-for="item in qsParse(scope.row.skuCombo)">
              <span class="mr-[12px]">
                <el-text size="small"> {{ item.name }} : {{ item.value }} </el-text>
              </span>
            </template>
            <template v-if="!qsParse(scope.row.skuCombo).length">
              <el-text size="small"> 主规格</el-text>
            </template>
          </template>
        </el-table-column>
        <el-table-column width="80px" label="数量">
          <template #default="scope"> x{{ scope.row.itemQty }}</template>
        </el-table-column>
        <el-table-column width="300px" label="配送信息">
          <template #default="scope">
            <div class="mb-[12px]">
              <el-text
                >配送方式:
                <template v-if="scope.row.order.needShip === 'Y'">
                  <DcDictValueText
                    :model-value="scope.row.order.shipType"
                    dict-type="DCSHP_SHIP_TYPE"
                  ></DcDictValueText>
                  <br />
                  <DcDateFormat v-model="scope.row.order.shipDate"></DcDateFormat>
                </template>
                <template v-else> 不需要配送</template>
              </el-text><br>
              <el-text
              >下单方式:
                <template v-if="scope.row.order.createType">
                  <DcDictValueText
                    :model-value="scope.row.order.createType"
                    dict-type="DCSHP_ORDER_CREATE_TYPE"
                  ></DcDictValueText>
                </template>
                <template v-else> 代客下单</template>
              </el-text>
            </div>
            <div>
              <el-text>收货人: {{ scope.row.order.recipient }}</el-text>
            </div>
            <div>
              <el-text>收货人电话: {{ scope.row.order.recipientPhone }}</el-text>
            </div>
            <div>
              <el-text
                >收货地址:
                <!--                {{ scope.row.order.country }}{{ scope.row.order.province }}{{ scope.row.order.city}}{{ scope.row.order.zone }}{{ scope.row.order.recipientAddress }}-->
                {{ scope.row.order.city }}{{ scope.row.order.zone }}{{ scope.row.order.recipientAddress }}
              </el-text>
            </div>
            <div>
              <el-text>備注:
                {{ scope.row.order.remarks ?  scope.row.order.remarks : '(無)'}}
              </el-text>
            </div>
          </template>
        </el-table-column>

        <el-table-column width="100px" label="订单类型">
          <template #default="scope">
            <DcDictValueText
              v-model="scope.row.order.type"
              dict-type="DCSHP_ORDER_TYPE"
            ></DcDictValueText>
          </template>
        </el-table-column>

        <el-table-column width="100px" label="订单金额" prop="order.amount">
          <template #default="scope">
            <amount-text :amount="scope.row.order.amount"></amount-text>
          </template>
        </el-table-column>

        <el-table-column width="100px" label="状态">
          <template #default="scope">
            <DcDictValueText
              v-model="scope.row.order.status"
              dict-type="DCSHP_ORDER_STATUS"
            ></DcDictValueText>
          </template>
        </el-table-column>
        <el-table-column width="200px" label="下单时间">
          <template #default="scope">
            <DcDateFormat v-model="scope.row.order.createTime"></DcDateFormat>
          </template>
        </el-table-column>
        <el-table-column width="138px" label="操作" align="right" fixed="right">
          <template #default="scope">
            <div>
              <el-link
                @click="handleDetailClick ? handleDetailClick(scope.row.order) : $router.push({ path: 'detail', query: { id: scope.row.order.id } })"
                :underline="false"
                type="warning"
                class="mr-[12px]"
                >详情
              </el-link>
              <el-link
                v-if="!readOnly"
                @click="$router.push({ path: 'edit', query: { id: scope.row.order.id } })"
                :underline="false"
                type="primary"
                class="mr-[12px]"
                >编辑
              </el-link>
              <el-link                 v-if="!readOnly"
                                       :underline="false" @click="handleDelete(scope.row.order)" type="danger"
                >删除
              </el-link>
            </div>
            <div v-if="scope.row.order.status === 'WAIT_CONFIRM'" class="mt-[12px]">
              <el-link @click="handleConfirm(scope.row)" :underline="false" type="success"
                >确认订单
              </el-link>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        v-if="onPage"
        size="size"
        class="w-full flex justify-center mt-[24px]"
        :background="true"
        @size-change="handleLoadData"
        @change="handleLoadData"
        v-model:current-page="pager.pageIndex"
        v-model:page-size="pager.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pager.itemCount"
      />
    </div>
  </DcCommonContent>
</template>

<style scoped></style>
