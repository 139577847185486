<script setup lang="ts">
import { getCurrentInstance, nextTick, onMounted, ref, watch } from 'vue'
import DynamicFormUtils from 'devcude-common/src/utils/DynamicFormUtils'
import type DynamicTableFormModel from 'devcude-common/src/models/dynamic-form/DynamicTableFormModel'
import Pager from 'devcude-common/src/models/dynamic-form/Pager'
import type DataListField from '../../../data-entity/DataListField'
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'

const props = defineProps({
  mode: {
    type: String,
    default: 'form'
  },
  sort: {
    type: Boolean,
    default: false
  },
  sortKey: {
    type: String,
    default: ()=>{
      return'sortNum'
    }
  },
  queryParams: {
    type: Object
  },
  saveParams: {
    type: Object
  },
  size: {
    type: String
  },
  entity: {
    type: String

  },
  dataFormFlag: {
    type: String,
    default: 'FORM_TABLE'
  },
  modelValue: {
    type: Array,
    default: ()=>{
      return []
    }
  }
})

const emit = defineEmits(['tableSelectionChange', 'currentRowChange', 'update:modelValue'])
import Sortable from 'sortablejs'
import DcDynamicComp from '../form/DcDynamicComp.vue'
import EntityDesignDialog from '../biz/EntityDesignDialog.vue'
import { DcUtils } from 'devcude-common/src/utils/DcUtils'

const {proxy} = getCurrentInstance();
const router = proxy.$dcRouter
const route = proxy.$dcRoute

let model = ref<DynamicTableFormModel>()
const init = ref(false)
let tableItems = ref<Array<any>>([])
let pager = ref(new Pager())
const selection = ref([])

async function loadConfig() {
  const m = await DynamicFormUtils.getTableFormModel(props.entity as string, props.dataFormFlag)
  if (m && m.config) {
    model.value = m
    init.value = true
    handleLoadData()
    nextTick(() => {
      handleInitSortable()
    })
  }
}

let currentId = ref('')
let primaryKey = ref('id')
let currentRow = ref({})
let tableForm = ref(null)

async function handleLoadData() {

  if (props.mode != 'form') {
    return
  }

  if (!model.value || typeof model.value.list !== 'function') {
    return
  }

  let p = Object.assign({
    $list: model.value.config.flag
  }, props.queryParams ? props.queryParams : {}, model.value.pager, {
    onPage: false,
    orders: `${props.sortKey}@asc`
  })
  const resp = await model.value?.list(p)
  resp.data.items.forEach((item) => {
    item._$tableId = uuidv4()
  })
  tableItems.value = resp.data.items

  pager.value = Object.assign(pager.value)
  _.assign(pager.value, _.pick(resp.data.pager, _.keys(pager.value)))



}


async function handleSelectionChange(e: any) {
  selection.value = e
  emit('tableSelectionChange', e)
}


function handleRowClick(row) {
  currentId.value = row[primaryKey.value]
  currentRow.value = row
}

let mergeSaveParams = ref(Object.assign({}, { id: currentId.value }, props.saveParams))

watch(currentId, () => {
  mergeSaveParams.value = Object.assign({}, { id: currentId.value }, props.saveParams)
  emit('currentRowChange', currentRow.value)
})

watch(() => props.queryParams, (v) => {
  if (props.mode == 'form') handleLoadData()
})


function handleAdd(data?) {
  let item = {}
  model.value?.config.fields.forEach((e: DataListField) => {
    let defaultValue = e.defaultValue
    if (e.defaultValue && (e.defaultValue.startsWith('KEY@') || e.defaultValue.startsWith('DATE@'))) {
      defaultValue = ''
    }
    if (e.dataEntityFieldFlag) item[e.dataEntityFieldFlag] = (data && data[e.dataEntityFieldFlag]) ? data[e.dataEntityFieldFlag] : defaultValue
  })
  item['_$tableId'] = uuidv4()
  tableItems.value.push(item)
  unSaveRows.value.push(item)

}

async function handleDelete(row: any) {
  if (row.id && props.mode == 'form') {
    let resp = await model.value?.delete({ id: row.id })
    if (resp.status == 200) {
      tableItems.value.splice(tableItems.value.indexOf(row), 1)
      unSaveRows.value.splice(unSaveRows.value.indexOf(row), 1)
    }
  } else {
    tableItems.value.splice(tableItems.value.indexOf(row), 1)
    unSaveRows.value.splice(unSaveRows.value.indexOf(row), 1)

  }
}

const unSaveRows = ref<Array<any>>([])

function handleRecordUnSaveRow(row) {
  if (unSaveRows.value.indexOf(row) == -1) unSaveRows.value.push(row)
}

async function handleSave(row, del: boolean = true) {
  let resp


  if (row.id) {
    resp = await model.value?.update({ $form: props.dataFormFlag }, row, props.saveParams)
  } else {
    resp = await model.value?.add({ $form: props.dataFormFlag }, row, props.saveParams)
  }
  if (resp.status == 200) {  // 更广泛的成功状态码检查
    row.id = resp.data.id
    if (del) unSaveRows.value.splice(unSaveRows.value.indexOf(row), 1)
    return row
  } else {
    console.error(`Save failed with status: ${resp.status}`)
    throw row
  }

}

let isBatchSaveDoing = false

async function handleBatchSave() {
  if (isBatchSaveDoing) {
    return
  }
  isBatchSaveDoing = true
  try {
    console.log('等待批量保存', JSON.stringify(unSaveRows))

    let unSuccessRows = []
    while (unSaveRows.value.length) {
      try {
        await handleSave(unSaveRows.value.pop(), false)
      } catch (e) {
        unSuccessRows.push(e)
      }
    }

    unSaveRows.value.push(...unSuccessRows)

  } catch (e) {
    console.log(e)
  } finally {
    isBatchSaveDoing = false
  }


}

function handleInitSortable() {
  console.log("handleInitSortable")

  if (!props.sort) {
    return
  }
  // 获取el-table DOM
  if (!tableForm.value || !tableForm.value['$el']) {
    return
  }
  let tbody = tableForm.value['$el'].querySelectorAll('tbody')[0]

  if (!tbody) {
    return
  }
  //
  Sortable.create(tbody as HTMLElement, {
    animation: 150,
    ghostClass: 'blue-background-class',
    // handle: '.drag_btn', // 如果需要点击某个图标拖拽的话需要吧那个图标的class写在这里
    // 写完以上部分就已经可以实现基本的拖拽功能了，以下是拓展
    //始拖拽事件
    onEnd: function(/**Event*/evt) {
      const movedItem = tableItems.value.splice(evt.oldIndex as number, 1)[0]
      tableItems.value.splice(evt.newIndex as number, 0, movedItem)
      tableItems.value.forEach((e, i) => {
        e[props.sortKey] = i
        handleRecordUnSaveRow(e)
      })
    }
  })
}




loadConfig()

defineExpose({ handleAdd })

function generateAttrs(row: any, config: any): Attrs {
  return DcUtils.componentAttr({
    row: row,
    saveParams: props.saveParams,
    queryParams: props.queryParams,
    query: route.value.query
  }, config)
}


watch(()=>tableItems, () => {
  console.log("dddd")
  emit('update:modelValue', tableItems.value)
}, { deep: true })

</script>

<template>
  <div v-if="init" class="w-full h-full flex flex-col relative">

    <div class="flex-1  overflow-hidden">
      <div class="h-full max-h-full">
        <el-table ref="tableForm" :size="size" height="100%" :highlight-current-row="!!currentId"
                  @row-click="handleRowClick"
                  :row-style="{cursor:'pointer'}" :current-row-key="currentId" :data="tableItems"
                  @selectionChange="handleSelectionChange"
                  :row-key="(row:any)=>{return row._$tableId}" border>
          <el-table-column v-if="model?.config?.fields.find(item=>(item['showStatus'] == 'Y' && item['expend'] == 'Y'))"
                           type="expand">
            <template #default="scope">

              <div class="p-[12px]">
                <el-descriptions direction="horizontal" :column="2" border>
                  <template v-for="item in model.config.fields" :key="item.name">

                    <el-descriptions-item width="150px" v-if="item['showStatus'] == 'Y' && item['expend'] == 'Y'"
                                          :label="item['name']">
                      <slot :name="item['dataEntityFieldFlag']" :row="scope.row" :item="item">
                        <el-input v-if="!item['formComp']"
                                  :placeholder="item['placeholder'] ? item['placeholder'] : item['name']"
                                  :disabled="item['isDisable'] == 'Y'" @change="handleRecordUnSaveRow(scope.row)"
                                  @input="handleRecordUnSaveRow(scope.row)"
                                  v-model="scope.row[item['dataEntityFieldFlag']]"></el-input>
                        <dc-dynamic-comp v-else v-bind:is="item['formComp'] as string"
                                         v-model="scope.row[item['dataEntityFieldFlag']]"
                                         v-bind="generateAttrs(scope.row,item)"></dc-dynamic-comp>


                      </slot>
                    </el-descriptions-item>

                  </template>
                </el-descriptions>
              </div>


            </template>
          </el-table-column>
          <template v-for="item in model?.config?.fields" :key="item.name">

            <el-table-column v-if="item['showStatus'] == 'Y' && item['expend'] != 'Y'" :label="item['name']"
                             :prop="item['dataEntityFieldFlag']">


              <template #default="scope">
                <slot :name="item['dataEntityFieldFlag']" :row="scope.row">
                  <el-form-item class="!mb-0">
                    <el-input v-if="!item['formComp']"
                              :placeholder="item['placeholder'] ? item['placeholder'] : item['name']"
                              :disabled="item['isDisable'] == 'Y'" @change="handleRecordUnSaveRow(scope.row)"
                              @input="handleRecordUnSaveRow(scope.row)"
                              v-model="scope.row[item['dataEntityFieldFlag']]"></el-input>
                    <dc-dynamic-comp v-else v-bind:is="item['formComp'] as string"
                                     v-model="scope.row[item['dataEntityFieldFlag']]"
                                     v-bind="generateAttrs(scope.row,item)"></dc-dynamic-comp>

                  </el-form-item>
                </slot>

              </template>


            </el-table-column>
          </template>
          <el-table-column label="操作" align="right">
            <template #default="scope">
              <el-link v-if="mode === 'form'" @click="handleSave(scope.row)" type="primary"
                       :disabled="unSaveRows.indexOf(scope.row) == -1" :underline="false" class="mr-[12px]">保存
              </el-link>
              <el-link @click="handleDelete(scope.row)" class="mr-[12px]" :underline="false" type="danger">删除
              </el-link>
              <el-link v-if="props.sort" class="drag-btn" :underline="false" type="info">排序</el-link>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="mt-[16px]">
      <el-button @click="handleAdd()">新增一行</el-button>
      <el-button v-if="mode === 'form'" @click="handleBatchSave()">批量保存</el-button>
    </div>
    <entity-design-dialog v-if="model && model.config && model.config.entityId" :entity-id="model.config.entityId" init-tab="formDesign"></entity-design-dialog>
  </div>
  <div v-else class="w-full h-[500px] flex justify-center bg-[#fff] items-center">
    无法加载模型列表表单:{{ entity }}@{{ dataFormFlag }}
  </div>
</template>

<style scoped>

</style>