import  Menu from './Menu'
import DropdownConfig from './DropdownConfig'

export default class LayoutConfig{

  menuConfig:Menu[] = []

  userDropdownConfig:DropdownConfig = new DropdownConfig()

  langDropdownConfig:DropdownConfig = new DropdownConfig()

}