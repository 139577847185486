import type { RouteRecordRaw } from 'vue-router'
const routers: RouteRecordRaw = {
  path: '/shp-vip-level',
  name:'ShpVip',
  meta: {
    title: '会员等级管理'
  },
  children: [
    {
      path: 'list',
      name: 'ShpVipList',
      meta: {
        title: '会员等级列表'
      },
      component: ()=>{return import("@/views/shp-vip-level/ShpVipLevelList.vue")}
    },
    {
      path: 'add',
      name: 'ShpVipAdd',
      meta: {
        title: '会员等级新增'
      },
      component: ()=>{return import("@/views/shp-vip-level/ShpVipLevelAdd.vue")}
    },
    {
      path: 'edit',
      name: 'ShpVipEdit',
      meta: {
        title: '会员等级编辑'
      },
      component: ()=>{return import("@/views/shp-vip-level/ShpVipLevelEdit.vue")}
    }
  ]
}
export default routers
