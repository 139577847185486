<script setup lang="ts">
import { ref } from 'vue'
import DictCompCommonApi from '../../api/DictCompCommonApi'

const props = defineProps({
  dictType: { type: String, default: '' },
  disabled: { type: Boolean, default: false },
})

const v = defineModel<Array<any>>([])

const options = ref<Array>()

async function initData() {

  const cacheKey = ('DC_DICT_SELECT_CACHE')
  if (!window[cacheKey]) {
    window[cacheKey] = DictCompCommonApi.dictData()
  }

  window[cacheKey].then(resp => {
    options.value = resp.data.items.filter(e => e.dictTypeFlag === props.dictType)
  })

}

function handleChange(e, item) {
  if (e) {
    if (v.value.indexOf(item.dictValue) == -1) {
      v.value.push(item.dictValue)
    }
  } else {
    if (v.value.indexOf(item.dictValue) != -1) {
      v.value.splice(v.value.indexOf(item.dictValue), 1)
    }
  }
}

initData()

</script>

<template>
  <div class="dynamic-check-box">
    <span v-for="item in options" class="!mr-[16px]">
      <el-checkbox v-if="v.indexOf(item.dictValue) != -1"  @change="handleChange($event,item)"
                   :label="item.name" :checked="true" class="!mr-[16px]" :disabled="disabled"/>
      <el-checkbox v-else  @change="handleChange($event,item)" :label="item.name"
                   :checked="false" class="!mr-[16px]" :disabled="disabled"/>
    </span>

  </div>
</template>

<style scoped>

</style>