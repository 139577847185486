<script setup lang="ts">
import '@wangeditor/editor/dist/css/style.css' // 引入 css
import { onBeforeUnmount, ref, shallowRef, onMounted, watch } from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import {ElMessage} from "element-plus";

const modeValue = defineModel<String>()
const props = defineProps({
  placeholder: {
    type: String,
    default: '请输入内容...'
  },
  mode: {
    type: String,
    default: 'default'
  },
  minHeight: {
    type: String,
    default: '300px'
  },
  disabled:{
    type:Boolean,
    default:false
  },
})
const editorRef = shallowRef()
const toolbarConfig = {}
const editorConfig = {
  placeholder: props.placeholder,
  MENU_CONF: {
    uploadImage: {},
    uploadVideo: {}
  }
}

// 插入图片
editorConfig.MENU_CONF['uploadImage'] = {
  server: '/api/nasServer/upload',
  fieldName: 'file',
  // 单个文件的最大体积限制，默认为 2M
  maxFileSize: 100 * 1024 * 1024,

  // 单个文件上传失败
  onFailed() {
    ElMessage.error("上传失败");
  },

  // 上传错误，或者触发 timeout 超时
  onError() {
    ElMessage.error("上传失败");
  },

  // 自定义上传
  customInsert(res: any, insertFn: any) {
    if(res.status !== 200) {
      ElMessage.error("上传失败");
      return;
    }
    const data = res.data;
    const {fileCode} = data.item;
    const url = "/api/nasServer/access/" + fileCode;
    insertFn(url)
  }
}

// 插入视频
editorConfig.MENU_CONF['uploadVideo'] = {
  server: '/api/nasServer/upload',
  fieldName: 'file',
  // 单个文件上传失败
  onFailed(file: File, res: any) {
    ElMessage.error("上传失败");
  },


  // 上传错误，或者触发 timeout 超时
  onError() {
    ElMessage.error("上传失败");
  },

  // 自定义上传
  customInsert(res: any, insertFn: any) {
    if(res.status !== 200) {
      ElMessage.error("上传失败");
      return;
    }
    const data = res.data;
    const {fileCode} = data.item;
    const url = "/api/nasServer/access/" + fileCode;
    insertFn(url)
  }
}


// 组件销毁时，也及时销毁编辑器
onBeforeUnmount(() => {
  const editor = editorRef.value
  if (editor == null) return
  editor.destroy()
})



const handleCreated = (editor: any) => {
  editorRef.value = editor // 记录 editor 实例，重要！
  watch(()=>props.disabled,()=>{
    console.log("asdd",props.disabled)
    if(props.disabled){
      editorRef.value?.disable()
    }else{
      editorRef.value?.enable()
    }
  },{immediate:true})
}


</script>

<template>
  <div class="dc-editor">
    <Toolbar
      style="border-bottom: 1px solid #ccc"
      :editor="editorRef"
      :defaultConfig="toolbarConfig"
      :mode="mode"
    />
    <Editor
      :disabled="disabled"
      v-model="modeValue"
      :defaultConfig="editorConfig"
      :mode="mode"
      :style="{height:minHeight}"
      @onCreated="handleCreated"
    />

  </div>

</template>

<style>
.dc-editor {
  border: 1px solid #ccc;
  position: relative;
  line-height: initial;
}


</style>
