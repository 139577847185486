import Components from './src/components'
import routes from './src/router/sdk'
import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import './src/assets/main.scss'
import $http from 'devcude-common/src/utils/DcHttp'

export default class DcCompSdk {

  static initHttp(router) {
    $http.request().interceptors.response.use(response => {
        if (response.data.status === 401) {
          router.push('/login')
          return
        }

        return Promise.resolve(response)
      },
      error => {
      console.log(error)
        if (error.status === 401) {
          router.push('/login')
          return
        }
        return Promise.reject(error)
      })
  }

  static initRouter(app, router) {
    routes.forEach((route) => {
      router.addRoute(route)
    })
    console.log(router.getRoutes())
    app.use(router)
    app.config.globalProperties.$dcRouter = router
    app.config.globalProperties.$dcRoute = router.currentRoute
  }

  static init(app, router) {
    this.initHttp(router)

    Components.register(app)
    app.use(ElementPlus)
    for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
      app.component(key, component)
    }
    this.initRouter(app, router)
  }
}