
const modules = import.meta.glob('./**/*.vue', { eager: true });


export default class Components{

  static async register(app) {

    for (const key in modules){
      app.component(modules[key].default.__name,modules[key].default);
    }


  }
}