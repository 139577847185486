<script setup lang="ts">
import { ref, watch } from 'vue'
import DynamicFormUtils from 'devcude-common/src/utils/DynamicFormUtils'

const props = defineProps({
  showCheckBox:{
    type: Boolean,
    default:false
  },
  entity: {
    type: String,
    default: ''
  },
  queryParams:{
    type:Object,
    default:()=>{
      return  null
    }
  },
  modelValue:{
    type:String,
    default: ''
  },
  multiple:{
    type:Boolean,
    default:false
  },
  primaryKey: {
    default: 'id'
  },
  labelKey: {
    default: 'name'
  }
})

const emit = defineEmits(['update:modelValue'])

const v = ref();


const data = ref<Array<any>>([])
let model = ref();
async function initData() {

  model.value = await DynamicFormUtils.getListModel(props.entity)
  if(model.value){
    let resp = await model.value.dataModel.list(props.queryParams,{ onPage: false })
    data.value = resp.data.items
  }

}

watch(()=>props.modelValue,()=>{
  if(props.modelValue){
    v.value =  props.multiple ? props.modelValue.split(',') : props.modelValue
  }else{
    v.value = props.multiple ? [] : props.modelValue
  }
},{immediate:true})

watch(()=>props.queryParams,()=>{
  initData();
},{deep:true})

watch(v,()=>{
  emit('update:modelValue', props.multiple ? v.value.join(',') : v.value )
})


initData();




</script>

<template>
  <el-select
    class="min-w-[180px]"
    v-if="data"
    v-model="v"
    :multiple="multiple"
    filterable
  >
    <el-option
      v-for="item in data"
      :key="item.dictValue"
      :label="item[props.labelKey]"
      :value="item[props.primaryKey] as string"
    />
  </el-select>
</template>

<style scoped>

</style>