import type { RouteRecordRaw } from 'vue-router'
const entity = "DC_DIY_COMP"
const routers: RouteRecordRaw = {
  path: '/diy-comp',
  name:'diy-comp',
  meta: {
    title: '自定义组件'
  },
  children: [
    {
      path: 'list',
      name: 'diy-comp-list',
      meta: {
        title: '自定义组件列表'
      },
      props:{
        entity:entity,
      },
      component: ()=>{return import("../../views/dynamic-form/DynamicListView.vue")}
    },
  ]
}
export default routers
