import type { RouteRecordRaw } from 'vue-router'

const routers: RouteRecordRaw = {
  path: '/shp-agent-settle-record',
  name: 'ShpAgentSettleRecord',
  meta: {
    title: '渠道结算管理'
  },
  children: [
    {
      path: 'list',
      name: 'ShpAgentSettleRecordList',
      meta: {
        title: '渠道结算列表'
      },
      component: () => {
        return import('@/views/shp-agent-settle-record/ShpAgentSettleList.vue')
      }
    },
    {
      path: 'add',
      name: 'ShpAgentSettleRecordAdd',
      meta: {
        title: '渠道结算新增'
      },
      component: () => {
        return import('@/views/shp-agent-settle-record/ShpAgentSettleAdd.vue')
      }
    },
    {
      path: 'edit',
      name: 'ShpAgentSettleRecordEdit',
      meta: {
        title: '渠道结算编辑'
      },
      component: () => {
        return import('@/views/shp-agent-settle-record/ShpAgentSettleAdd.vue')
      }
    }
  ]
}
export default routers
