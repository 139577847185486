<script setup lang="ts">
import { nextTick, onMounted, ref } from 'vue'

import ShpDeliveryOrderPrintTpl from '@/components/ShpDeliveryOrderPrintTpl.vue'
import printJs from 'print-js'
import qs from 'qs'

import $api from '../config/api'
import $http from 'devcude-common/src/utils/DcHttp'

const v = ref(false)
const loading = ref(false)
const emit = defineEmits(['finish'])
const print = ref(false)
const printItems = ref([])
const printTime = new Date()

async function handleClose() {
  printItems.value = []
  v.value = false
}

async function show(items) {
  printItems.value = JSON.parse(JSON.stringify(items))
  v.value = true
}

function handlePrint() {
  print.value = true

  let key = 'shp_delivery_order_print_' + new Date().getTime()
  sessionStorage.setItem(key, JSON.stringify(printItems.value))
  window.open('/shp-delivery-order-print-view?key=' + key)
  return;
  // nextTick(() => {
  //   printJs({
  //     printable: 'print-ctx',
  //     type: 'html',
  //     // header:"二维码打印",
  //     targetStyles: ['*'],
  //     repeatTableHeader: true,
  //     style: '@page {margin:2.4cm 2cm ;resolution: 300dpi;}',
  //     //解决字体样式失效的问题
  //     font_size: '',
  //     onLoadingStart: () => {
  //       console.log('onLoadingStart')
  //     },
  //     onLoadingEnd: async () => {
  //       console.log('onLoadingEnd')
  //
  //       let ids = printItems.value.map((e) => e.id)
  //       await $http.post(
  //         $api.shpDeliveryOrderRef.updatePrintTimes,
  //         qs.stringify({ ids: ids.join(',') })
  //       )
  //       v.value = false
  //       emit('finish')
  //     },
  //     onPrintDialogClose: () => {
  //       print.value = false
  //     }
  //   })
  // })
}

defineExpose({ show })
</script>

<template>
  <el-dialog v-model="v" @close="handleClose" width="800px">
    <div>打印订单总数:{{ printItems.length }}</div>
    <el-divider></el-divider>
    <el-scrollbar v-loading="loading" height="800px">
      <div id="print-ctx">
        <template v-for="(item, i) in printItems" :key="i">
          <div class="break-after-page">
            <ShpDeliveryOrderPrintTpl
              :tpl="item.dcShopPrintTpl"
              :data="item.shpOrderParam"
              :printTime="printTime"
              :logo="item.dcShopLogo"
            ></ShpDeliveryOrderPrintTpl>

            <el-divider
              v-if="!print"
              class="print-line"
              border-style="dashed"
              content-position="center"
            ></el-divider>
          </div>
        </template>
      </div>
    </el-scrollbar>
    <el-divider></el-divider>
    <div class="flex justify-center">
      <el-button size="large" @click="handlePrint()" type="primary">打印</el-button>
      <el-button size="large" @click="v = false">取消</el-button>
    </div>
  </el-dialog>
</template>

<style></style>
