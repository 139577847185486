import type { RouteRecordRaw } from 'vue-router'

const routers: RouteRecordRaw = {
  path: '/admin/shp-order',
  name: 'AdminShpOrder',
  meta: {
    title: '订单管理管理'
  },
  children: [
    {
      path: 'list',
      name: 'AdminShpOrderList',
      meta: {
        title: '订单管理列表'
      },
      component: () => {
        return import('@/views/admin/shp-order/ShpOrderList.vue')
      }
    },
    {
      path: 'wait-deal-list',
      name: 'AdminShpOrderWaitList',
      meta: {
        title: '等待处理订单'
      },
      component: () => {
        return import('@/views/shp-order/ShpOrderWaitList.vue')
      }
    },
    {
      path: 'detail',
      name: 'AdminShpOrderDetail',
      meta: {
        title: '订单管理详情'
      },
      component: () => {
        return import('@/views/admin/shp-order/ShpOrderDetail.vue')
      }
    },
    {
      path: 'edit',
      name: 'AdminShpOrderEdit',
      meta: {
        title: '订单管理编辑'
      },
      component: () => {
        return import('@/views/shp-order/ShpOrderEdit.vue')
      }
    }
  ]
}
export default routers
