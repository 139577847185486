<script setup lang="ts">
import { ref, watch } from 'vue'
import DynamicFormCommonApi from '../../api/DynamicFormCommonApi'
import type FormComp from '../../../data-entity/FormComp'

const props = defineProps({
  formComp:{
    type:String,
    default:''
  },
  modelValue: {
    type: String,
    default: ''
  },
});
const emit = defineEmits(["update:modelValue"])
const v = ref('')


async function initData() {

  const cacheKey = ('DC_FORM_COMP_SELECT')
  if (!window[cacheKey]) {
    window[cacheKey] = DynamicFormCommonApi.formComps();
  }

  window[cacheKey].then(resp => {
    if(!props.modelValue){
     const e = resp.data.items.find(e=>e.flag === props.formComp);
     if(e && e.props){
       v.value = e.props
     }
    }else{
      v.value = props.modelValue
    }
  })

}

watch(v,()=>{
  emit("update:modelValue",v)
})



initData()

</script>

<template>
  <dc-json-editor v-model="v"></dc-json-editor>
</template>

<style scoped>

</style>