<script setup lang="ts">
import HomeView from 'dc-comp-sdk/src/views/HomeView.vue'
import SystemSelect from '@/components/SystemSelect.vue'
import $http from 'devcude-common/src/utils/DcHttp'
import $api from '../config/api'
import { ref, watch } from 'vue'
import { useSystemStore } from '@/stores/system'
import logoUrl from '@/assets/logo.png'
const systemStore = useSystemStore()
const systemFlag = ref()
console.log(systemStore)
async function init() {
  let resp = await $http.post($api.shpAdminAuth.userinfo)
  systemFlag.value = (resp.data.passport.sysMenuFlag + '@' + resp.data.passport.dcShopNum)
  systemStore.configUserinfo(resp.data.passport)
  watch(systemFlag, () => {
    systemStore.changeSystem(systemFlag.value)
  })

}

init()


</script>

<template>
  <HomeView :logo-url="logoUrl" :user-name="systemStore.userinfo.name">
    <template v-slot:header-left>
      <div class="p-[12px]">
        <SystemSelect v-model="systemFlag"></SystemSelect>
      </div>
    </template>
  </HomeView>
</template>

<style scoped>

</style>