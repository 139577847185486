import { getCurrentInstance } from 'vue'

function useRouter() {
  const proxy = getCurrentInstance()
  console.log("useRouter:",proxy.$dcRouter)
  return proxy.$dcRouter
}

function useRoute() {
  const proxy = getCurrentInstance()
  console.log("useRoute:",proxy.$dcRouter)

  return proxy.$dcRoute
}

export { useRouter, useRoute }