import  DataModel from '../../models/dynamic-form/DataModel'
import  Pager from '../../models/dynamic-form/Pager'
import  FieldConfig from '../../models/dynamic-form/FieldConfig'
import BaseModel from '../../models/dynamic-form/BaseModel'

export default class DynamicFormModel extends BaseModel{


  formItems:Array<FieldConfig> = []

  formData:any = {}

  config:DynamicFormConfig = {}

  constructor(dataModel: DataModel) {
    super(dataModel);
    super.dataModel = dataModel
  }


}