<script setup lang="ts">
import { getCurrentInstance, ref, watch } from 'vue'
import { type RouteLocationMatched} from 'vue-router'
const {proxy} = getCurrentInstance();

const router = proxy.$dcRouter
const route = proxy.$dcRoute
let paths = ref<RouteLocationMatched[]>([])
watch(router?.currentRoute, () => {
    paths.value = router?.currentRoute?.value?.matched
  },
  { immediate: true })

</script>

<template>
  <div class="flex overflow-hidden flex-col">
    <div class="p-[20px]">
      <el-page-header
        @back="$router.back()"
        class="bg-[#fff] p-[16px] pl-[24px] pr-[24px]"
        icon="arrow-left"
        :content="$route.meta?.title as string"
      >
        <template #extra>
          <el-breadcrumb separator="/">
            <template v-for="(path,i) in paths">
              <el-breadcrumb-item v-if="path.components" :key="'a' + i" :to="{ path: path.path,replace: true}">
                {{ path.meta.title }}
              </el-breadcrumb-item>
              <el-breadcrumb-item v-else :key="'b' + i">{{ path.meta.title }}</el-breadcrumb-item>
            </template>

          </el-breadcrumb>
        </template>
      </el-page-header>
    </div>
    <div class="flex-1 overflow-hidden ">
      <el-scrollbar class=" p-[20px] pt-0">
        <div>
          <slot></slot>
        </div>
        <el-footer class="flex items-center justify-center">
          <span class="text-[#808695] text-[14px]">
            Copyright © {{ new Date().getFullYear() }} DevCude All Rights Reserved
          </span>
        </el-footer>
      </el-scrollbar>
    </div>


  </div>
</template>

<style scoped></style>
