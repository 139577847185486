import $http from 'devcude-common/src/utils/DcHttp';
import {stringify} from 'qs';
import DcResponse from 'devcude-common/src/models/DcResponse'
import type { AxiosRequestConfig } from 'axios'



export default class DataFormFieldApi {

    static  async  list(dataFormFieldParam:{
           entityFlag?:string
           dataFormFlag?:string
           type?:string
           dataEntityFieldFlag?:string
           name?:string
           defaultValue?:string
           id?:number
           version?:number
           valid?:number
           createTime?:date
           updateTime?:date
           showStatus?:string
           sortNum?:number
           isDisable?:string
           isRequire?:string
           placeholder?:string
           dataFormId?:number
           expend?:string
           formComp?:string
           formCompProps?:string
    }={}
    ,
pagerBean:{
           pageIndex?:any
           pageSize?:any
           isFirst?:boolean
           isLast?:boolean
           itemCount?:any
           onPage?:boolean
           pageCount?:any
           orders?:any
           conds?:any
    }={}
    ,
    config: AxiosRequestConfig = {}
  ):Promise<DcResponse> {
        const $_params = stringify(Object.assign({},
        dataFormFieldParam
            ,
        pagerBean
        ))


  const resp = await $http.post("/api/dataFormField/list",$_params,config);
  return resp;
  }
    static  async  add(dataFormFieldParam:{
           entityFlag?:string
           dataFormFlag?:string
           type?:string
           dataEntityFieldFlag?:string
           name?:string
           defaultValue?:string
           id?:number
           version?:number
           valid?:number
           createTime?:date
           updateTime?:date
           showStatus?:string
           sortNum?:number
           isDisable?:string
           isRequire?:string
           placeholder?:string
           dataFormId?:number
           expend?:string
           formComp?:string
           formCompProps?:string
    }={}
    ,
    config: AxiosRequestConfig = {}
  ):Promise<DcResponse> {
        const $_params = stringify(Object.assign({},
        dataFormFieldParam
        ))


  const resp = await $http.post("/api/dataFormField/add",$_params,config);
  return resp;
  }
    static  async  batchDelete(ids:{
           ids?:any
    }={}
    ,
    config: AxiosRequestConfig = {}
  ):Promise<DcResponse> {
        const $_params = stringify(Object.assign({},
        ids
        ))


  const resp = await $http.post("/api/dataFormField/batchDelete",$_params,config);
  return resp;
  }
    static  async  update(dataFormFieldParam:{
           entityFlag?:string
           dataFormFlag?:string
           type?:string
           dataEntityFieldFlag?:string
           name?:string
           defaultValue?:string
           id?:number
           version?:number
           valid?:number
           createTime?:date
           updateTime?:date
           showStatus?:string
           sortNum?:number
           isDisable?:string
           isRequire?:string
           placeholder?:string
           dataFormId?:number
           expend?:string
           formComp?:string
           formCompProps?:string
    }={}
    ,
    config: AxiosRequestConfig = {}
  ):Promise<DcResponse> {
        const $_params = stringify(Object.assign({},
        dataFormFieldParam
        ))


  const resp = await $http.post("/api/dataFormField/update",$_params,config);
  return resp;
  }
    static  async  delete(dataFormFieldParam:{
           entityFlag?:string
           dataFormFlag?:string
           type?:string
           dataEntityFieldFlag?:string
           name?:string
           defaultValue?:string
           id?:number
           version?:number
           valid?:number
           createTime?:date
           updateTime?:date
           showStatus?:string
           sortNum?:number
           isDisable?:string
           isRequire?:string
           placeholder?:string
           dataFormId?:number
           expend?:string
           formComp?:string
           formCompProps?:string
    }={}
    ,
    config: AxiosRequestConfig = {}
  ):Promise<DcResponse> {
        const $_params = stringify(Object.assign({},
        dataFormFieldParam
        ))


  const resp = await $http.post("/api/dataFormField/delete",$_params,config);
  return resp;
  }
    static  async  detail(dataFormFieldParam:{
           entityFlag?:string
           dataFormFlag?:string
           type?:string
           dataEntityFieldFlag?:string
           name?:string
           defaultValue?:string
           id?:number
           version?:number
           valid?:number
           createTime?:date
           updateTime?:date
           showStatus?:string
           sortNum?:number
           isDisable?:string
           isRequire?:string
           placeholder?:string
           dataFormId?:number
           expend?:string
           formComp?:string
           formCompProps?:string
    }={}
    ,
    config: AxiosRequestConfig = {}
  ):Promise<DcResponse> {
        const $_params = stringify(Object.assign({},
        dataFormFieldParam
        ))


  const resp = await $http.post("/api/dataFormField/detail",$_params,config);
  return resp;
  }

}