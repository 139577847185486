import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import './config/api'
import './assets/main.scss'
import "./style/index.css"
import DcCompSdk from 'dc-comp-sdk'
import ShpAdminComp from './components'
import router from './router'
import { useSystemStore } from '@/stores/system'
import ECharts from 'vue-echarts'
import 'echarts'
import   * as OpenCC from 'opencc-js'
// 全局注册组件
const app = createApp(App)
app.component('ECharts', ECharts)
app.use(createPinia())
DcCompSdk.init(app, router)
ShpAdminComp.register(app)



// 全局监听 `copy` 事件
document.addEventListener('copy', async (event) => {
  // 阻止默认复制行为
  event.preventDefault();

  // 获取选中的文本
  const selection = window.getSelection().toString();

  if (selection) {
    const converter = OpenCC.Converter({ from: 'cn', to: 'hk' });

    // 使用 OpenCC 将简体转换为繁体
    const traditionalText =  converter(selection);
    console.log(selection,traditionalText)
    // 设置剪贴板内容为转换后的繁体中文
    event.clipboardData.setData('text/plain', traditionalText);
  }

});

//初始化SDK
app.mount('#app')

window['LOGIN_SUCCESS'] = async () => {
  let systemStore = useSystemStore()
  const flag = localStorage.getItem('DCSHP_SYSTEM_FLAG')
  if (!flag) {
    systemStore.$patch({ showDcshopChooseBox: true })
  } else {
    try {
      await systemStore.changeSystem(flag)
    }catch (e){
      systemStore.$patch({ showDcshopChooseBox: true })

    }
  }
}




