import type { RouteRecordRaw } from 'vue-router'
const routers: RouteRecordRaw = {
  path: '/bundle-product',
  name:'BundleProduct',
  meta: {
    title: '凑单商品管理'
  },
  children: [
    {
      path: 'list',
      name: 'BundleProductList',
      meta: {
        title: '凑单商品列表'
      },
      component: ()=>{return import("@/views/bundle-product/BundleProductList.vue")}
    }
  ]
}
export default routers
