import type { RouteRecordRaw } from 'vue-router'

const routers: RouteRecordRaw = {
  path: '/admin/shp-delivery-order-print',
  name: 'ShpDeliveryOrderPrint',
  meta: {
    title: '送货单管理'
  },
  children: [
    {
      path: 'list',
      name: 'ShpDeliveryOrderPrintList',
      meta: {
        title: '送货单列表'
      },
      component: () => {
        return import('@/views/admin/shp-delivery-order-print/ShpDeliveryOrderPrintList.vue')
      }
    },
    {
      path: 'ref-list',
      name: 'ShpDeliveryOrderRefList',
      meta: {
        title: '送货单明细列表'
      },
      component: () => {
        return import('@/views/admin/shp-delivery-order-print/ShpDeliveryOrderRefList.vue')
      }
    },
    {
      path: 'add',
      name: 'ShpDeliveryOrderPrintAdd',
      meta: {
        title: '送货单新增'
      },
      component: () => {
        return import('@/views/admin/shp-delivery-order-print/ShpDeliveryOrderPrintAdd.vue')
      }
    },
    {
      path: 'edit',
      name: 'ShpDeliveryOrderPrintEdit',
      meta: {
        title: '送货单编辑'
      },
      component: () => {
        return import('@/views/admin/shp-delivery-order-print/ShpDeliveryOrderPrintEdit.vue')
      }
    }
  ]
}
export default routers
