import { createRouter, createWebHistory } from 'vue-router'
import ShpHomeView from '../views/ShpHomeView.vue'

const modules = import.meta.glob('./modules/**/*.ts', { eager: true })
const routes = {
  path: '/',
  name: 'ShpHome',
  meta: {
    title: '首页'
  },
  component: ShpHomeView,
  children: [
    {
      path: '/',
      name: 'ShpHomeIndex',
      meta: {
        title: '数据中心'
      },
      component: () => {
        return import('@/views/ShpHomeIndex.vue')
      }
    }
  ]
}

for (const path in modules) {
  routes.children.push(modules[path].default)
}
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [routes,
    {
      path: '/shp-delivery-order-print-view',
      name: 'ShpDeliveryOrderPrintView',
      meta: {
        title: '送货单打印'
      },
      component: () => {
        return import('@/views/admin/shp-delivery-order-print/ShpDeliveryOrderPrintView.vue')
      }
    },

    {
      path: '/shp-delivery-sticker-print-view',
      name: 'ShpDeliveryStickerPrintView',
      meta: {
        title: '贴纸打印'
      },
      component: () => {
        return import('@/views/admin/shp-delivery-order-print/ShpDeliveryStickerPrintView.vue')
      }
    },
  ]
})


export default router
