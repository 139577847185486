import 'tailwindcss/tailwind.css';
import '../assets/main.scss'
const modules = import.meta.glob('./**/*.vue', { eager: true });

export default class Components{

  static async register(app) {
    for (let key in modules){
      app.component(modules[key].default.__name,modules[key].default);
    }
  }
}