<script setup lang="ts">
import $http from 'devcude-common/src/utils/DcHttp'
import $api from '../config/api'
import qs from 'qs'
import { ref } from 'vue'
import { useSystemStore } from '@/stores/system'
import SystemSelect from '@/components/SystemSelect.vue'

const systemStore = useSystemStore()
const formRules = ref({
  flag: [
    { required: true, message: '请选择系统', trigger: 'blur' }
  ]
})

const formInst = ref()
const fd = ref({ flag: '' })


async function handleConfirm() {
  let checkForm = await formInst.value.validate()
  if (!checkForm) {
    return
  }
  systemStore.changeSystem(fd.value.flag)
}


</script>

<template>
  <el-dialog title="选择系统" width="380px">

    <div class="bg-[#fff] flex flex-col h-full !justify-start">
      <el-form ref="formInst" :model="fd" :rules="formRules" size="large" label-width="auto" class="bg-[#fff] ">

        <el-form-item prop="flag">
          <SystemSelect v-model="fd.flag"></SystemSelect>
        </el-form-item>

        <div class="flex justify-end">
          <el-button @click="handleConfirm" type="primary" size="large">确定</el-button>
        </div>
      </el-form>
    </div>

  </el-dialog>
</template>

<style scoped>

</style>