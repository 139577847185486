<script setup lang="ts">
import { ref, watch } from 'vue'
import $http from 'devcude-common/src/utils/DcHttp'
import $api from '../config/api'
import qs from 'qs'

const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  },
  multiple: {
    type: Boolean,
    default: false
  },
  disable: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['update:modelValue'])

const v = ref()


const data = ref<Array<any>>([])

async function initData() {
  let resp = await $http.post($api.shpProdCat.tree,qs.stringify({onPage:false}))
  data.value = resp.data.items
}

watch(() => props.modelValue, () => {

  if (props.modelValue) {
    v.value = props.multiple ? props.modelValue.split(',') : props.modelValue
  } else {
    v.value = props.multiple ? [] : props.modelValue
  }
}, { immediate: true })

watch(v, () => {
  emit('update:modelValue', props.multiple ? v.value.join(',') : v.value)
})


initData()

const _props = { multiple: props.multiple,value:'id',label:'name',emitPath:false}
</script>

<template>
  <el-cascader v-if="data" :options="data" v-model="v" filterable :props="_props" />

</template>

<style scoped>

</style>
