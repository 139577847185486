import type { RouteRecordRaw } from 'vue-router'
const entity = "DC_ROLE"
const routers: RouteRecordRaw = {
  path: '/role',
  name:'role',
  meta: {
    title: '角色管理'
  },
  children: [
    {
      path: 'add',
      name: 'role-add',
      props:{
          entity:entity,
          action:'add',
      },
      meta: {
        title: '角色信息'
      },
      component: ()=>{return import("../../views/dynamic-form/DynamicFormView.vue")}
    },
    {
      path: 'list',
      name: 'role-list',
      meta: {
        title: '角色列表'
      },
      props:{
        entity:entity,
      },
      component: ()=>{return import("../../views/dynamic-form/DynamicListView.vue")}
    },
    {
      path: 'edit',
      name: 'role-edit',
      meta: {
        title: '角色编辑'
      },
      props:{
        entity:entity,
        action:'edit',
      },
      component: ()=>{return import("../../views/dynamic-form/DynamicFormView.vue")}
    }

  ]
}
export default routers
