<script setup lang="ts">
import { computed, nextTick, ref, toRefs, watch } from 'vue'
import DcSinglePicShow from 'dc-comp-sdk/src/components/form/DcSinglePicShow.vue'
import $http from 'devcude-common/src/utils/DcHttp'
import qs from 'qs'
import $api from '../../config/api'
import PkgMustItemChooseBox from '@/components/PkgMustItemChooseBox.vue'
import PkgCustItemChooseBox from '@/components/PkgCustItemChooseBox.vue'
import AmountText from '@/components/AmountText.vue'

const props = defineProps({
  mode: {
    type: String,
    default: 'orderItem'
  },
  shpAgentId: {
    type: String,
    default: () => {
      return ''
    }
  }
})
let confirmCallback = ref({})
const productInfo = ref({})
const dialogVisible = ref(false)
const productId = ref()
const currentSku = ref()
const formInst = ref()
const formData = {
  name: '',
  pic: '',
  productNum: '',
  pkgCustItems: [],
  pkgMustItems: [],
  productId: '',
  categoryName: '',
  categoryId: '',
  price: '',
  origPrice: '',
  agentPrice: '',
  basePrice:'',
  extraPrice: 0,
  agentSettlePrice: '',
  skuId: '',
  skuCombo: '',
  prodSkuCombo: '',
  remarks: '',
  skuRefProdName:'',
  skuRefProdItemQty:'',
  itemQty: 1
}
const fd = ref(Object.assign({}, formData))

const formRules = ref({
  price: [
    { required: ['PKG_ITEM','orderItem'].indexOf(props.mode) !== -1, message: '商品单价不能为空', trigger: 'blur' }
  ],
  agentSettlePrice: [
    { required: ['PKG_ITEM','orderItem'].indexOf(props.mode) !== -1 && !!props.shpAgentId, message: '渠道结算价不能为空', trigger: 'blur' }
  ],
  costPrice: [
    { required: ['PKG_ITEM','orderItem'].indexOf(props.mode) !== -1, message: '成本价不能为空', trigger: 'blur' }
  ],
  origPrice: [
    { required: ['PKG_ITEM','orderItem'].indexOf(props.mode) !== -1, message: '原价不能为空', trigger: 'blur' }
  ],
  basePrice: [
    { required:  ['PKG_ITEM','orderItem'].indexOf(props.mode) !== -1, message: '加价格不能为空', trigger: 'blur' }
  ], extraPrice: [
    { required:  ['PKG_ITEM','orderItem'].indexOf(props.mode) !== -1, message: '加价格不能为空', trigger: 'blur' }
  ],
  itemQty: [
    { required: ['PKG_ITEM','orderItem'].indexOf(props.mode) !== -1, message: '商品数量不能为空', trigger: 'blur' }
  ]
})

const skuFormRules = ref({})
const skuComps = ref([])

const rules = computed(() => {
  return Object.assign({}, formRules.value, skuFormRules.value)
})

watch(fd.value,()=>{
  let sum = 0;
  fd.value.pkgCustItems.forEach(pkgCustItem => {
    pkgCustItem.items.forEach((item) => {
         if(item.choosed){
           sum+=item.extraPrice;
         }
    })
  })
  fd.value.extraPrice = sum;
},{deep:true,immediate:true})

async function handleLoadData() {
  let resp = await $http.post($api.shpProduct.detail, qs.stringify({
    id: productId.value,
    shpAgentId: props.shpAgentId
  }))
  productInfo.value = resp.data.item

  const skuItems = productInfo.value.multiSku === 'Y' ? productInfo.value.skuItems : []

  fd.value.name = productInfo.value.name
  fd.value.prodType = productInfo.value.type
  fd.value.pkgMustItems = productInfo.value.pkgMustItems
  fd.value.pkgCustItems = productInfo.value.pkgCustItems
  fd.value.productId = productInfo.value.id
  fd.value.categoryName = productInfo.value.categoryName
  fd.value.categoryId = productInfo.value.categoryId
  if (productInfo.value.pics) {
    fd.value.pic = productInfo.value.pics.split(',')
  }
  if (skuItems) {
    skuItems.forEach((skuItem) => {
      skuFormRules.value[`sku_${skuItem.id}`] = [{ required: true, message: `请选择${skuItem.name}`, trigger: 'blur' }]
      fd.value[`sku_${skuItem.id}`] = ''
    })
  }

  if (productInfo.value.multiSku === 'N') {
    currentSku.value = productInfo.value.mainSku
    fd.value.pic = productInfo.value.pics ? productInfo.value.pics.split(',')[0] : ''
    fd.value.productNum = productInfo.value.number
    fd.value.price = currentSku.value.price
    fd.value.costPrice = currentSku.value.costPrice
    fd.value.extraPrice = currentSku.value.extraPrice
    fd.value.stockQty = currentSku.value.stockQty
    fd.value.origPrice = currentSku.value.origPrice
    fd.value.agentPrice = currentSku.value.agentPrice
    fd.value.agentSettlePrice = currentSku.value.agentSettlePrice
    fd.value.skuId = currentSku.value.id
    fd.value.skuCombo = currentSku.value.skuCombo
    fd.value.prodSkuCombo = currentSku.value.prodSkuCombo
  }

}


async function handleShow(id, callBack: Function) {
  if (!id) {
    return
  }
  productId.value = id
  await handleLoadData()
  dialogVisible.value = true
  confirmCallback.value = callBack

}


async function handleShowOrderItem(orderItem, callBack: Function) {
  if (!orderItem.productId) {
    return
  }
  productId.value = orderItem.productId
  await handleLoadData()
  if (orderItem.skuCombo) skuComps.value = orderItem.skuCombo.split('&')
  nextTick(() => {
    fd.value = orderItem
  })
  dialogVisible.value = true
  confirmCallback.value = callBack
}

async function handleChooseSku(skuItem, skuItemValue, index) {
  fd.value['sku' + '_' + skuItem.id] = skuItemValue.itemValue
  skuComps.value[index] = (skuItem.name + '=' + skuItemValue.itemValue)
}

async function handleConfirm() {
  let checkForm = await formInst.value.validate()
  if (!checkForm) {
    return
  }
  dialogVisible.value = false
  if(!fd.value.extraPrice){
      fd.value.extraPrice = 0;
  }
  if (confirmCallback.value) {
    confirmCallback.value(fd.value)
  }
  handleCancel()
  formInst.value.clearValidate()

}

async function handleCancel() {
  dialogVisible.value = false
  skuComps.value = []
  productInfo.value = null
  currentSku.value = null
  fd.value = Object.assign({}, formData)
}

function areQueriesEqual(query1, query2) {
  // Convert query strings to URLSearchParams objects
  const params1 = new URLSearchParams(query1);
  const params2 = new URLSearchParams(query2);

  // Create objects from the parameters
  const obj1 = Object.fromEntries(params1);
  const obj2 = Object.fromEntries(params2);

  // Compare the two objects as JSON strings
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

watch(skuComps, () => {
  let skuKeys = skuComps.value.join('&')
  if (!productInfo.value || productInfo.value.multiSku === 'N') {
    return
  }
  let sku = null;

  for (let key in productInfo.value.skuInfos){
      if(areQueriesEqual(key,skuKeys)){
        sku =productInfo.value.skuInfos[key]
      }
  }

  console.log('sku', productInfo.value.pics)
  currentSku.value = sku
  if (sku) {
    fd.value.pic = productInfo.value.pics ? productInfo.value.pics.split(',')[0] : ''
    fd.value.productNum = productInfo.value.number
    fd.value.price = sku.price
    fd.value.costPrice = sku.costPrice
    fd.value.stockQty = sku.stockQty
    fd.value.origPrice = sku.origPrice
    fd.value.agentPrice = sku.agentPrice
    fd.value.agentSettlePrice = sku.agentSettlePrice
    fd.value.skuId = sku.id
    fd.value.skuCombo = skuKeys
    // fd.value.name = sku.name
    fd.value.skuRefProdName = sku.name
    fd.value.skuRefProdItemQty = sku.itemQty
    // fd.value.itemQty = 1

  }

}, { deep: true })

defineExpose({ handleShow, handleShowOrderItem })

</script>

<template>
  <el-dialog v-model="dialogVisible" width="800px" class="!p-0 shp-prod-choose-box" :show-close="false">
    <div class="border-b p-[12px]">
      <div>
        <el-text>商品规格</el-text>
      </div>
    </div>
    <div class="p-[24px]">
      <el-form v-if="productInfo" ref="formInst" :model="fd" :rules="rules" label-width="auto">
        <el-form-item label="">
          <div class="flex">
            <DcSinglePicShow v-model="productInfo.pics" class="border" width="64px" height="64px"></DcSinglePicShow>
            <div class="pl-[12px] flex flex-col">
              <div>
                <el-text size="large">{{ productInfo.name }}</el-text>
              </div>
              <div>
                <el-text size="default">分类：{{ productInfo.categoryName }}</el-text>
              </div>
            </div>
          </div>
        </el-form-item>
        <template v-for="(item,itemIndex) in productInfo.skuItems" :key="itemIndex">
          <el-form-item :label="item.name" :prop="`sku_${item.id}`">
            <el-tag v-for="value in item.values" :key="value.id" size="default"
                    @click="handleChooseSku(item,value,itemIndex)"
                    :type=" fd[`sku_${item.id}`] === value.itemValue ? 'primary': 'info'"
                    class="mr-[12px] cursor-pointer">
              {{ value.itemValue }}
            </el-tag>
          </el-form-item>
        </template>

        <template v-if="productInfo.type === 'PKG' ">
          <el-form-item label="必选商品" prop="pkgMustItems" class="!max-w-full">
            <pkg-must-item-choose-box v-model="fd.pkgMustItems"></pkg-must-item-choose-box>
          </el-form-item>
          <el-form-item label="自选商品" prop="pkgCustItems" class="!max-w-full">
            <pkg-cust-item-choose-box v-model="fd.pkgCustItems"></pkg-cust-item-choose-box>
          </el-form-item>
        </template>

        <el-form-item v-if="currentSku" label="原价" prop="origPrice">
          <el-input v-model="fd.origPrice" placeholder="原价" :disabled="mode === 'sku'"></el-input>
        </el-form-item>
        <el-form-item v-if="currentSku" label="成本价" prop="costPrice">
          <el-input v-model="fd.costPrice" placeholder="成本价" :disabled="mode === 'sku'"></el-input>
        </el-form-item>

        <template  v-if="productInfo.type === 'PKG' ">
          <el-form-item v-if="currentSku" label="单价" prop="price">
            <el-input v-model="fd.price" placeholder="单价" :disabled="mode === 'sku'"></el-input>
          </el-form-item>

          <el-form-item v-if="currentSku" label="自选商品加价" prop="extraPrice">
            <el-input v-model="fd.extraPrice" placeholder="自选商品加价" :disabled="mode === 'sku'"></el-input>
          </el-form-item>
        </template>
        <template v-else>
          <el-form-item v-if="currentSku" label="单价" prop="price">
            <el-input v-model="fd.price" placeholder="单价" :disabled="mode === 'sku'"></el-input>
          </el-form-item>
        </template>


        <el-form-item v-if="currentSku && mode=='PKG_ITEM'" label="加价"  prop="extraPrice">
          <el-input v-model="fd.extraPrice" placeholder="加价"></el-input>
        </el-form-item>
        <el-form-item v-if="currentSku" label="库存数量" prop="stockQty">
          <el-input v-model="fd.stockQty" placeholder="库存数量" disabled></el-input>
        </el-form-item>

        <el-form-item v-if="currentSku && shpAgentId" label="渠道售价" prop="agentPrice">
          <el-input v-model="fd.agentPrice" placeholder="渠道售价" :disabled="mode === 'sku'"></el-input>
        </el-form-item>

        <el-form-item v-if="currentSku && shpAgentId" label="渠道结算价" prop="agentSettlePrice">
          <el-input v-model="fd.agentSettlePrice" placeholder="渠道结算价" :disabled="mode === 'sku'"></el-input>
        </el-form-item>

        <el-form-item v-if="[ 'orderItem','PKG_ITEM'].indexOf(props.mode) !== -1" label="数量" prop="itemQty">
          <el-input v-model="fd.itemQty" placeholder="数量"></el-input>
        </el-form-item>


        <el-form-item v-if="mode === 'orderItem'" label="备注" prop="remarks" >
          <el-input v-model="fd.remarks" type="textarea" placeholder="数量"></el-input>
        </el-form-item>

        <el-form-item v-if="currentSku && currentSku.name" label="关联商品名称" >
          <el-input v-model="currentSku.name" placeholder="关联商品名称" disabled></el-input>
        </el-form-item>

        <el-form-item v-if="currentSku && currentSku.itemQty" label="关联商品数量" class="!mb-0" >
          <el-input v-model="currentSku.itemQty" placeholder="关联商品数量" disabled></el-input>
        </el-form-item>

      </el-form>
    </div>

    <div class="flex justify-center border-t p-[12px]">
      <div>
        <el-button @click="handleConfirm" size="default" type="primary">确认</el-button>
        <el-button @click="handleCancel" class="ml-[12px]" size="default" type="info">取消</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<style scoped>

</style>
