<script setup lang="ts">
import { computed, ref, toRefs, watch } from 'vue'
import DcMultiPicWallUploader from 'dc-comp-sdk/src/components/form/DcMultiPicWallUploader.vue'
import { ElMessage } from 'element-plus'
import DcSinglePicShow from 'dc-comp-sdk/src/components/form/DcSinglePicShow.vue'

const props = defineProps({
  skuItems: {
    type: Array,
    default() {
      return []
    }
  },
  skuInfo: {
    type: Object,
    default() {
      return {}
    }
  },
  disabled: {
    type: Boolean,
    default: false
  }
})


const emit = defineEmits(['update:skuItems'])




const { skuItems, skuInfo } = toRefs(props)



function combineSkus(skus) {
  const result = []

  function combine(currentCombination, index) {
    if (index === skus.length) {
      const combinationWithComp = { ...currentCombination }
      combinationWithComp.skuCombo = Object.entries(currentCombination)
        .map(([key, value]) => `${key}=${value}`)
        .join('&')
      result.push(combinationWithComp)
      return
    }

    const currentSku = skus[index]
    currentSku.values.forEach(value => {
      currentCombination[currentSku.name] = value.itemValue
      combine(currentCombination, index + 1)
    })
  }

  combine({}, 0)

  return result
}



const skuData = computed(() => {
  let i = skuItems.value.filter(e => e.values.length)
  if (!i.length) {
    return []
  }
  const d = combineSkus(i)
  const skuCombos = []
  d.forEach((item) => {
    skuCombos.push(item.skuCombo)
    if (!skuInfo.value[item.skuCombo]) {
      skuInfo.value[item.skuCombo] = {
        pics: '',
        defChoose: 'N',
        price: '',
        origPrice: '',
        stockQty: '',
        stockQtyAlert: '',
        agentPrice: '',
        agentSettlePrice: '',
        skuCombo: item.skuCombo
      }
    }
    item.$skuInfo = skuInfo.value[item.skuCombo]
  })
  for (let key in skuInfo.value) {
    if (skuCombos.indexOf(key) == -1) {
      delete skuInfo.value[key]
    }
  }
  return d
})
</script>

<template>
  <div class="w-full">
    <div class="mt-[12px]">
      <el-table :data="skuData" border class="w-full">
        <el-table-column label="商品规格">
          <template v-for="item in skuItems">
            <el-table-column :label="item.name" v-if="item.values.length" :prop="item.name"></el-table-column>
          </template>
        </el-table-column>
        <el-table-column label="SKU图片">
          <template #default="scope">
            <DcSinglePicShow width="48px" height="48px"
                             :model-value="scope.row.$skuInfo['pics'] ? scope.row.$skuInfo['pics'].split(',')[0] : ''"></DcSinglePicShow>
          </template>
        </el-table-column>
        <el-table-column label="售价" prop="$skuInfo['price']"></el-table-column>
        <el-table-column label="原价" prop="$skuInfo['origPrice']"></el-table-column>
        <el-table-column label="成本价" prop="$skuInfo['costPrice']"></el-table-column>
        <el-table-column label="渠道售价">
          <template #default="scope">
            <el-input v-model="scope.row.$skuInfo['agentPrice']" placeholder="渠道售价" :disabled="disabled"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="渠道结算价">
          <template #default="scope">
            <el-input v-model="scope.row.$skuInfo['agentSettlePrice']" placeholder="渠道售价"
                      :disabled="disabled"></el-input>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>

</template>

<style scoped>

</style>