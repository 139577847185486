<script setup lang="ts">
import { ref, watch } from 'vue'
import ShpAgentSettleOrderListComp from '@/components/ShpAgentSettleOrderListComp.vue'

const v = defineModel(false)
const emits = defineEmits(['confirm'])
const orders = ref([])
const props = defineProps(['searchQuery', 'shpAgentId','excludeOrderIds'])

function show() {
  orders.value = []
  v.value = true
}

function handleConfirm() {
  emits('confirm', orders.value)
  orders.value = []
  v.value = false
}

function handleClose() {
  orders.value = []
  v.value = false

}

defineExpose({ show, handleClose })

</script>

<template>
  <el-dialog v-model="v" width="80%" @close="handleClose">
    <shp-agent-settle-order-list-comp :exclude-order-ids="excludeOrderIds" v-if="shpAgentId && v" :on-page="false" v-model="orders" :searchQuery="Object.assign({shpAgentId},props.searchQuery)"></shp-agent-settle-order-list-comp>
    <div class="mt-[24px] flex justify-center">
      <el-button @click="handleConfirm" type="primary" size="default">确认</el-button>
      <el-button @click="handleClose" size="default">取消</el-button>
    </div>
  </el-dialog>
</template>

<style scoped>

</style>