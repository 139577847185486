<script setup lang="ts">
    import { ref } from 'vue'

    const  v = ref()
</script>

<template>
  <el-input type="textarea"></el-input>
</template>

<style scoped>

</style>