import type { RouteRecordRaw } from 'vue-router'

const routers: RouteRecordRaw = {
  path: '/shp-setting',
  name: 'ShpSetting',
  meta: {
    title: '商城配置'
  },
  children: [
    {
      path: 'list',
      name: 'ShpSettingList',
      meta: {
        title: '配置列表'
      },
      component: () => {
        return import('@/views/shp-setting/ShpSettingList.vue')
      }
    },
    {
      path: 'edit',
      name: 'ShpSettingEdit',
      meta: {
        title: '配置编辑'
      },
      component: () => {
        return import('@/views/shp-setting/ShpSettingEdit.vue')
      }
    }
  ]
}
export default routers
