import type { RouteRecordRaw } from 'vue-router'
const entity = "DC_STORE"
const routers: RouteRecordRaw = {
  path: '/dc-store',
  name:'dc-store',
  meta: {
    title: '商户名称'
  },
  children: [

    {
      path: 'list',
      name: 'dc-store-list',
      meta: {
        title: '商户列表'
      },
      props:{
        entity:entity,
      },
      component: ()=>{return import("../../views/dynamic-form/DynamicListView.vue")}
    }

  ]
}
export default routers
