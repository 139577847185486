<script setup lang="ts">
import DataEntityFieldApi from '../../api/DataEntityFieldApi'
import { ref, watch } from 'vue'

const props = defineProps(['entityFlag', 'modelValue', 'nameValue', 'valueKey', 'labelKey'])
const items = ref<Array<any>>([])
const emit = defineEmits(['update:modelValue', 'update:nameValue'])
const v = ref({})

async function handleLoadData() {
  if (!props.entityFlag) {
    return
  }
  const cacheKey = ('FORM_FIELD_SELECT_CACHE@' + props.entityFlag)
  if (!window[cacheKey]) {
    window[cacheKey] = DataEntityFieldApi.list(props, { onPage: false })
  }

  window[cacheKey].then(resp => {
    if (resp.status === 200) {
      items.value = resp.data.items
      v.value = items.value.find(e => e['flag'] === props.modelValue) || null

    }
  })


}


watch(() => props.modelValue, (newValue) => {
  v.value = items.value.find(e => e['flag'] === props.modelValue) || null
})

watch(v, () => {
  if (v.value && v.value['flag']) emit('update:modelValue', v.value['flag'])
  if (v.value && !props.nameValue) emit('update:nameValue', v.value['name'])
}, { immediate: true })


watch(() => props, () => {

  handleLoadData()
}, { immediate: true })

</script>

<template>
  <el-select value-key="flag" filterable v-model="v">
    <el-option
      v-for="item in items"
      :key="item['flag']"
      :label="`${item['name']}[${item['flag']}]`"
      :value="item">
    </el-option>
  </el-select>
</template>

<style scoped>

</style>