<script setup lang="ts">
import { ref, defineModel, watch } from 'vue'
import $http from 'devcude-common/src/utils/DcHttp'
import $api from '@/config/api'
import qs from 'qs'

const v = defineModel()
const systems = ref([])

async function init() {
  let resp = await $http.post($api.shpAdminAuth.userSystems, qs.stringify({ onPage: false }))
  systems.value = resp.data.items
}

init()


</script>

<template>
  <el-select class="!min-w-[160px]" v-model="v">
    <el-option
      v-for="item in systems"
      :key="item.flag"
      :label="item['name']"
      :value="item['flag'] as string"
    />
  </el-select>
</template>

<style scoped>

</style>