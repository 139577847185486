//域名
const domain = window.location.host + '/api'

//端口
const port = null

//路径
const root = window.location.protocol + '//' + domain + (port ? (':' + port) : '')

//接口
const api = {
  authCompCommon: {
    login: `${root}/authCompCommon/login`,
    logout: `${root}/authCompCommon/logout`,
    userMenus: `${root}/authCompCommon/userMenus`,
    userinfo: `${root}/authCompCommon/userinfo`
  },
  areaCompCommon:{
    areaData: `${root}/areaCompCommon/areaData`,
  }
}

let $api = Object.assign({
  $base: {
    domain: domain, port: port, root: root
  }
}, api)
export default $api
