<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import { DcUtils } from 'devcude-common/src/utils/DcUtils'
import DynamicFormUtils from 'devcude-common/src/utils/DynamicFormUtils'

const props = defineProps({
  showCheckBox:{
    type: Boolean,
    default:false
  },
  entity: {
    type: String,
    default: ''
  },
  queryParams:{
    type:Object,
    default:()=>{
      return  null
    }
  },
  modelValue:{
    type:String,
    default: ''
  },
  multiple:{
    type:Boolean,
    default:false
  },
  primaryKey: {
    default: 'id'
  },
  parentKey: {
    default: 'parentId'
  },
  labelKey: {
    default: 'name'
  }
})

const emit = defineEmits(['update:modelValue'])

const v = ref();


const data = ref<Array<any>>([])
let model = ref();
async function initData() {

  model.value = await DynamicFormUtils.getListModel(props.entity)
  if(model.value){
    let resp = await model.value.dataModel.list(props.queryParams,{ onPage: false })
    data.value = DcUtils.array2Tree(resp.data.items,props.primaryKey,props.parentKey)
  }

}

watch(()=>props.modelValue,()=>{
   v.value =  props.multiple ? props.modelValue.split(',') : props.modelValue
},{immediate:true})

watch(()=>props.queryParams,()=>{
  initData();
},{deep:true})

watch(v,()=>{
    emit('update:modelValue', props.multiple ? v.value.join(',') : v.value )
})


initData();




</script>

<template>
  <el-cascader v-model="v"  :options="data" :props="{label:props.labelKey,value:props.primaryKey,emitPath:false,multiple:props.multiple}" />
</template>

<style scoped>

</style>