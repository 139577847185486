<script setup lang="ts">
import { ref, watch } from 'vue'
import DictCompCommonApi from '../../api/DictCompCommonApi'
import type DictTypeItem from '../../../data-entity/DictTypeItem'
import DataEntityFieldApi from '../../api/DataEntityFieldApi'

const props = defineProps({
  dictType: { type: String, default: '' },
  modelValue: {
    type: String,
    default: ''
  }
})

const v = ref()

const options = ref<Array<DictTypeItem>>()

watch(() => props.modelValue, () => {
  initData()
},{immediate:true})

async function initData() {

  const cacheKey = ('DC_DICT_SELECT_CACHE')
  if (!window[cacheKey]) {
    window[cacheKey] = DictCompCommonApi.dictData()
  }

  window[cacheKey].then(resp => {
    v.value = resp.data.items.find(e => e.dictTypeFlag === props.dictType && e.dictValue === props.modelValue)
    console.log(props.modelValue)
  })

}


</script>

<template>
  {{ v ? v.name : `${dictType}@${modelValue}未找到` }}
</template>

<style scoped>

</style>