<script setup lang="ts">
import { ref, toRef, watch } from 'vue'
import { DcUtils } from 'devcude-common/src/utils/DcUtils'
import DcDateFormat from 'dc-comp-sdk/src/components/form/DcDateFormat.vue'
import DcDictValueText from 'dc-comp-sdk/src/components/form/DcDictValueText.vue'

const props = defineProps(['data', 'tpl', 'logo'])
const printTime = ref(new Date())

function qsParse(qsString) {
  return DcUtils.qsParse(qsString)
}



</script>

<template>
  <div :class="tpl">
    <div id="shp-delivery-order-pint-tpl" class="shp-delivery-order-pint-tpl p-[20px] bg-[#ffffff]">
      <div>
        <div class="card-title">地區送貨報告</div>
        <table class="w-full">
          <tr>
            <td class="td-hd r-label">訂單數量</td>
            <td>{{ data.items.length }}</td>
            <td class="td-hd r-label">打印時間</td>
            <td>
              <DcDateFormat v-model="printTime" format="YYYY-MM-DD HH:mm"></DcDateFormat>
            </td>
          </tr>
        </table>
      </div>

      <div class="mt-[20px]">
        <div class="card-title">訂單列表</div>

        <div>
           <div v-for="(item,index) in data.items" :key="index">
             <div>
               <div>
                 <el-text>{{ item.shpOrderParam.number }} {{item.shpOrderParam.remarks ? item.shpOrderParam.remarks : '(無)'}}</el-text>
               </div>
<!--               <div>-->
<!--                 <el-text-->
<!--                 >配送時間:-->
<!--                   <DcDateFormat-->
<!--                     v-model="item.shpOrderParam.shipDate"-->
<!--                   ></DcDateFormat>-->
<!--                 </el-text>-->
<!--               </div>-->
<!--                <div>-->
<!--                  <el-text-->
<!--                  >配送方式:-->
<!--                    <template v-if="item.shpOrderParam.needShip === 'Y'">-->
<!--                      <DcDictValueText-->
<!--                        :model-value="item.shpOrderParam.shipType"-->
<!--                        dict-type="DCSHP_SHIP_TYPE"-->
<!--                      ></DcDictValueText>-->
<!--                    </template>-->
<!--                    <template v-else> 不需要配送</template>-->
<!--                  </el-text>-->
<!--                </div>-->
<!--                  <div>-->
<!--                    <el-text-->
<!--                    >備注: {{item.shpOrderParam.remarks ? item.shpOrderParam.remarks : '(無)'}}-->
<!--                    </el-text>-->
<!--                  </div>-->
             </div>
             <div>
               <el-text
               >
<!--                 {{ item.shpOrderParam.city }}{{ item.shpOrderParam.zone }}-->
                 {{ item.shpOrderParam.recipientAddress }}
               </el-text>
             </div>
             <div>
               <el-text>{{ item.shpOrderParam.recipient }}</el-text>
             </div>
             <div>
               <el-text>{{ item.shpOrderParam.recipientPhone }}</el-text>
             </div>

             <br>
           </div>
        </div>

      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.rrs {
  $theme: rrs;
  @import '@/assets/print-tpl';
}

.jsh {
  $theme: jsh;
  @import '@/assets/print-tpl';
}

.lb {
  $theme: lb;
  @import '@/assets/print-tpl';
}

.tl {
  $theme: tl;
  @import '@/assets/print-tpl';
}

.sy {
  $theme: sy;
  @import '@/assets/print-tpl';
}

.ttt {
  $theme: ttt;
  @import '@/assets/print-tpl';
}
</style>
