<script setup lang="ts">
import { ref, defineModel, watch } from 'vue'
import $http from 'devcude-common/src/utils/DcHttp'
import $api from '@/config/api'
import qs from 'qs'

const props = defineProps(['phone'])
const emits = defineEmits('change')
const v = defineModel()
const items = ref([])
const keyword = ref()

async function init() {
  handleSearch(true)
}

watch(() => props.phone, () => {
  keyword.value = props.phone
})

watch(keyword, () => {
  handleSearch()
})

async function handleSearch(init) {
  let resp = await $http.post($api.shpUser.list, qs.stringify({ phone: keyword.value, onPage: true }))
  items.value = resp.data.items
  if (items.value.length === 1 && !init) {
    v.value = items.value[0].id
  } else {
    v.value = ''
  }
}


watch(v, () => {
  emits('change', items.value.find(e => e.id === v.value))
})

init()


</script>

<template>
  <el-select class="!min-w-[160px]" reserve-keyword filterable remote
             :remote-method="(e)=>{keyword=e}"
             v-model="v" clearable>
    <el-option

      v-for="item in items"
      :key="item.id"
      :label="item['name']"
      :value="item['id'] as string"
    />
  </el-select>
</template>

<style scoped>

</style>