<script setup lang="ts">
import { ref } from 'vue'

import qs from 'qs'

import $api from '../config/api'
import $http from 'devcude-common/src/utils/DcHttp'
import DcDictRadio from 'dc-comp-sdk/src/components/form/DcDictRadio.vue'

const v = ref(false)
const loading = ref(false)
const emit = defineEmits(['finish'])
let formData = {
  id: '',
  remarks: ''
}

async function show(data) {
  formData = data
  fd.value = Object.assign({},data)
  v.value = true
}

const SAVE_URL = $api.shpOrder.updateRemarks

const formInst = ref()
const fd = ref(Object.assign({}, formData))

const formRules = ref({
  remarks: [{ required: true, message: '備注不能爲空', trigger: 'blur' }]
})

function handleReset() {
  fd.value = Object.assign({}, formData)
}

async function handleSave() {
  let checkForm = await formInst.value.validate()
  if (!checkForm) {
    return
  }
  try {
    await $http.post(SAVE_URL, qs.stringify(fd.value), { showTips: true })
    emit('finish')
    v.value = false;
  } catch (e: any) {
    if (e.status === 400) {
      console.log(e)
      let errorTips = new Map()
      e.data.errorFields.forEach((e: any) => {
        errorTips.set(e.name, e.message)
      })
      formInst.value.fields.forEach((e: any) => {
        if (errorTips.get(e.prop)) {
          e.validateState = 'error'
          e.validateMessage = errorTips.get(e.prop)
          //手动校验  显示错误文本
          formInst.value.validateField(e.prop)
        }
      })
    }
  }
}

defineExpose({ show })
</script>

<template>
  <el-dialog v-model="v" @close="handleClose" width="600px">
    <el-form
      ref="formInst"
      :model="fd"
      :rules="formRules"
      size="large"
      label-width="auto"
      class="bg-[#fff] !min-w-[500px]"
    >
      <el-form-item label="订单备注" prop="remarks">
        <el-input v-model="fd.remarks" placeholder="订单备注" type="textarea"></el-input>
      </el-form-item>

      <div class="flex justify-center">
        <el-button @click="handleSave" type="primary" size="large">保存</el-button>
        <el-button @click="handleReset" size="large">重置</el-button>
      </div>
    </el-form>
  </el-dialog>
</template>

<style></style>
