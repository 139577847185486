<script setup lang="ts">
import ECharts from 'vue-echarts'
import { ref } from 'vue'
import $http from 'devcude-common/src/utils/DcHttp'
import $api from '../config/api'

const option = ref({
  tooltip: {
    trigger: 'item',
    formatter: '{a} <br/>{b}: {c} ({d}%)'
  },
  legend: {
    orient: 'vertical',
    top: "center",
    right: '20%',
    // bottom: 30,
    // left: 'center',
  },
  series: [
    {
      name: '热销商品',
      type: 'pie',
      radius: ['40%', '70%'],
      avoidLabelOverlap: false,
      center: ['30%', '50%'],
      itemStyle: {
        borderRadius: 10,
        borderColor: '#fff',
        borderWidth: 2
      },
      label: {
        show: false,
        position: 'center'
      },
      emphasis: {
        label: {
          show: false,
          fontSize: 40,
          fontWeight: 'bold'
        }
      },
      labelLine: {
        show: false
      },
      data: []
    }
  ]
})

async function init() {
  let resp = await $http.post($api.shpCommon.productSaleRange)
  let items = resp.data.items
  let seriesData = []
  let legendData = []
  items.forEach((e: any)=>{
    legendData.push(e.name)
    seriesData.push({name:e.name,value:e.salesCount})
  })
  option.value.legend.data = legendData;
  option.value.series[0].data = seriesData;
}

init()


</script>

<template>
  <ECharts v-if="option" :option="option"></ECharts>
</template>

<style scoped>

</style>
