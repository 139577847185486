import type { RouteRecordRaw } from 'vue-router'

const routers: RouteRecordRaw = {
  path: '/admin/purchase-report',
  name: 'PurchaseReport',
  meta: {
    title: '采购报表'
  },
  children: [
    {
      path: 'list',
      name: 'PurchaseReportList',
      meta: {
        title: '采购报表列表'
      },
      component: () => {
        return import('@/views/admin/purchase-report/PurchaseReportList.vue')
      }
    },
    {
      path: 'report-item-list',
      name: 'PurchaseReportItemList',
      meta: {
        title: '采购明细列表'
      },
      component: () => {
        return import('@/views/admin/purchase-report/PurchaseReportItemList.vue')
      }
    },
    {
      path: 'add',
      name: 'PurchaseReportAdd',
      meta: {
        title: '采购报表新增'
      },
      component: () => {
        return import('@/views/admin/purchase-report/PurchaseReportAdd.vue')
      }
    },
    {
      path: 'edit',
      name: 'PurchaseReportEdit',
      meta: {
        title: '采购报表编辑'
      },
      component: () => {
        return import('@/views/admin/purchase-report/PurchaseReportEdit.vue')
      }
    }
  ]
}
export default routers
