<script setup lang="ts">
import { nextTick, onMounted, ref, watch } from 'vue'
import DynamicFormUtils from 'devcude-common/src/utils/DynamicFormUtils'
import type DynamicTableFormModel from 'devcude-common/src/models/dynamic-form/DynamicTableFormModel'
import Pager from 'devcude-common/src/models/dynamic-form/Pager'
import type DataListField from '../../../data-entity/DataListField'
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'

const props = defineProps({
  queryParams: {
    type: Object
  },
  saveParams: {
    type: Object
  },
  size: {
    type: String
  },
  entity: {
    type: String
  },
  dataFormFlag: {
    type: String,
    default: 'FORM_TABLE'
  }
})


const emit = defineEmits(['tableSelectionChange', 'currentRowChange'])
import Sortable from 'sortablejs'
import DcDynamicComp from '../form/DcDynamicComp.vue'
import EntityDesignDialog from '../biz/EntityDesignDialog.vue'

let model = ref<DynamicTableFormModel>()
const init = ref(false)
let tableItems = ref<Array<any>>([])
let pager = ref(new Pager())
const selection = ref([])

async function loadConfig() {
  const m = await DynamicFormUtils.getTableFormModel(props.entity as string, props.dataFormFlag)
  if (m && m.config) {
    model.value = m
    init.value = true
    handleLoadData()
  }

}

let currentId = ref('')
let primaryKey = ref('id')
let currentRow = ref({})
let tableForm = ref(null)

async function handleLoadData() {

  if (!model.value || typeof model.value.list !== 'function') {
    return
  }

  let p = Object.assign({      $list: model.value.config.flag
  }, props.queryParams ? props.queryParams : {}, model.value.pager, {
    onPage: false,
    orders: 'sortNum@asc'
  })
  const resp = await model.value?.list(p)
  resp.data.items.forEach((item) => {
    item._$tableId = uuidv4()
  })
  tableItems.value = resp.data.items

  pager.value = Object.assign(pager.value)
  _.assign(pager.value, _.pick(resp.data.pager, _.keys(pager.value)))

  nextTick(() => {
    handleInitSortable()
  })

}


async function handleSelectionChange(e: any) {
  selection.value = e
  emit('tableSelectionChange', e)
}


function handleRowClick(row) {
  currentId.value = row[primaryKey.value]
  currentRow.value = row
}

let mergeSaveParams = ref(Object.assign({}, { id: currentId.value }, props.saveParams))

watch(currentId, () => {
  mergeSaveParams.value = Object.assign({}, { id: currentId.value }, props.saveParams)
  emit('currentRowChange', currentRow.value)
})

watch(() => props.queryParams, (v) => {
  handleLoadData()
})


function handleAdd(data?) {
  let item = {}
  model.value?.config.fields.forEach((e: DataListField) => {
    let defaultValue = e.defaultValue
    debugger
    if (e.defaultValue.startsWith('KEY@') || e.defaultValue.startsWith('DATE@')) {
      defaultValue = ''
    }
    if (e.dataEntityFieldFlag) item[e.dataEntityFieldFlag] = (data && data[e.dataEntityFieldFlag]) ? data[e.dataEntityFieldFlag] : defaultValue
  })
  item['_$tableId'] = uuidv4()
  tableItems.value.push(item)
  unSaveRows.value.push(item)

}

async function handleDelete(row: any) {
  if (row.id) {
    let resp = await model.value?.delete({ id: row.id })
    if (resp.status == 200) {
      tableItems.value.splice(tableItems.value.indexOf(row), 1)
      unSaveRows.value.splice(unSaveRows.value.indexOf(row), 1)
    }
  } else {
    tableItems.value.splice(tableItems.value.indexOf(row), 1)
    unSaveRows.value.splice(unSaveRows.value.indexOf(row), 1)

  }
}

const unSaveRows = ref<Array<any>>([])

function handleRecordUnSaveRow(row) {
  if (unSaveRows.value.indexOf(row) == -1) unSaveRows.value.push(row)
}

async function handleSave(row, del: boolean = true) {
  let resp


  if (row.id) {
    resp = await model.value?.update({$form:props.dataFormFlag}, row, props.saveParams)
  } else {
    resp = await model.value?.add({$form:props.dataFormFlag}, row, props.saveParams)
  }
  if (resp.status == 200) {  // 更广泛的成功状态码检查
    row.id = resp.data.id
    if (del) unSaveRows.value.splice(unSaveRows.value.indexOf(row), 1)
    return row
  } else {
    console.error(`Save failed with status: ${resp.status}`)
    throw row
  }

}

let isBatchSaveDoing = false

async function handleBatchSave() {
  if (isBatchSaveDoing) {
    return
  }
  isBatchSaveDoing = true
  try {
    console.log('等待批量保存', JSON.stringify(unSaveRows))

    let unSuccessRows = []
    while (unSaveRows.value.length) {
      try {
        await handleSave(unSaveRows.value.pop(), false)
      } catch (e) {
        unSuccessRows.push(e)
      }
    }

    unSaveRows.value.push(...unSuccessRows)

  } catch (e) {
    console.log(e)
  } finally {
    isBatchSaveDoing = false
  }


}

function handleInitSortable() {
  // 获取el-table DOM
  if (!tableForm.value || !tableForm.value['$el']) {
    return
  }
  let tbody = tableForm.value['$el'].querySelectorAll('tbody')[0]

  if (!tbody) {
    return
  }
  //
  Sortable.create(tbody as HTMLElement, {
    animation: 150,
    ghostClass: 'blue-background-class',
    // handle: '.drag_btn', // 如果需要点击某个图标拖拽的话需要吧那个图标的class写在这里
    // 写完以上部分就已经可以实现基本的拖拽功能了，以下是拓展
    //始拖拽事件
    onEnd: function(/**Event*/evt) {
      const movedItem = tableItems.value.splice(evt.oldIndex as number, 1)[0]
      tableItems.value.splice(evt.newIndex as number, 0, movedItem)
      tableItems.value.forEach((e, i) => {
        e['sortNum'] = i
        handleRecordUnSaveRow(e)
      })
    }
  })
}


onMounted(() => {
  handleInitSortable()
})

// watch(model.value.tableItems,()=>{
//   if(model.value && model.value.tableItems){
//     model.value.tableItems.forEach((e, i) => {
//       if(!e._$tableId){
//         e._$tableId = uuidv4();
//       }
//     })
//   }
//
// },{deep:true})

loadConfig()

defineExpose({ handleAdd })

interface Attrs {
  placeholder: string,
  disabled: boolean,
  onChange: (event: Event) => void,
  onInput: (event: Event) => void
}

function generateAttrs(row: any, item: any): Attrs {

  let attrs = {
    placeholder: item.placeholder || item.name,
    disabled: item.isDisable === 'Y',
    onChange: (event: Event) => handleRecordUnSaveRow(row),
    onInput: (event: Event) => handleRecordUnSaveRow(row)
  }

  const formCompProps = typeof item['formCompProps'] === 'object' ? item['formCompProps'] : JSON.parse(item['formCompProps'] ? item['formCompProps'] : '{}')

  let v = {}
  for (let key in row) {
    let k = `{{@row.${key}}}`
    v[k] = row[key]
  }

  for (let key in props.queryParams) {
    let k = `{{@qp.${key}}}`
    v[k] = props.queryParams[key]
  }


  if (item['formCompProps']) {
    for (let key in formCompProps) {
      attrs[key] = v[formCompProps[key]] ? v[formCompProps[key]] : formCompProps[key]
    }
  }

  return attrs

}

</script>

<template>
  <div v-if="init" class="w-full h-full flex flex-col relative">
    <div class="mb-[16px]">
      <el-button @click="handleAdd()">新增一行</el-button>
      <el-button @click="handleBatchSave()">批量保存</el-button>
    </div>
    <div class="flex-1  overflow-hidden">
      <div class="h-full max-h-full">
        <el-table ref="tableForm" :size="size" height="100%" :highlight-current-row="!!currentId"
                  @row-click="handleRowClick"
                  :row-style="{cursor:'pointer'}" :current-row-key="currentId" :data="tableItems"
                  @selectionChange="handleSelectionChange"
                  :row-key="(row:any)=>{return row._$tableId}" border>
          <el-table-column v-if="model?.config?.fields.find(item=>(item['showStatus'] == 'Y' && item['expend'] == 'Y'))"
                           type="expand">
            <template #default="scope">

              <div class="p-[12px]">
                <el-descriptions direction="horizontal" :column="2" border>
                  <template v-for="item in model.config.fields" :key="item.name">

                    <el-descriptions-item width="150px" v-if="item['showStatus'] == 'Y' && item['expend'] == 'Y'"
                                          :label="item['name']">
                      <slot :name="item['dataEntityFieldFlag']" :row="scope.row" :item="item">
                        <el-input v-if="!item['formComp']"
                                  :placeholder="item['placeholder'] ? item['placeholder'] : item['name']"
                                  :disabled="item['isDisable'] == 'Y'" @change="handleRecordUnSaveRow(scope.row)"
                                  @input="handleRecordUnSaveRow(scope.row)"
                                  v-model="scope.row[item['dataEntityFieldFlag']]"></el-input>
                        <dc-dynamic-comp v-else v-bind:is="item['formComp'] as string"
                                         v-model="scope.row[item['dataEntityFieldFlag']]"
                                         v-bind="generateAttrs(scope.row,item)"></dc-dynamic-comp>


                      </slot>
                    </el-descriptions-item>

                  </template>
                </el-descriptions>
              </div>


            </template>
          </el-table-column>
          <template v-for="item in model?.config?.fields" :key="item.name">

            <el-table-column v-if="item['showStatus'] == 'Y' && item['expend'] != 'Y'" :label="item['name']"
                             :prop="item['dataEntityFieldFlag']">


              <template #default="scope">
                <slot :name="item['dataEntityFieldFlag']" :row="scope.row">
                  <el-form-item class="!mb-0">
                    <el-input v-if="!item['formComp']"
                              :placeholder="item['placeholder'] ? item['placeholder'] : item['name']"
                              :disabled="item['isDisable'] == 'Y'" @change="handleRecordUnSaveRow(scope.row)"
                              @input="handleRecordUnSaveRow(scope.row)"
                              v-model="scope.row[item['dataEntityFieldFlag']]"></el-input>
                    <dc-dynamic-comp v-else v-bind:is="item['formComp'] as string"
                                     v-model="scope.row[item['dataEntityFieldFlag']]"
                                     v-bind="generateAttrs(scope.row,item)"></dc-dynamic-comp>

                  </el-form-item>
                </slot>

              </template>


            </el-table-column>
          </template>
          <el-table-column width="100px" label="操作" align="right">
            <template #default="scope">
              <el-link @click="handleSave(scope.row)" type="primary" :disabled="unSaveRows.indexOf(scope.row) == -1"
                       :underline="false" class="mr-[12px]">保存
              </el-link>
              <el-link @click="handleDelete(scope.row)" :underline="false" type="danger">删除
              </el-link>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <entity-design-dialog v-if="model && model.config && model.config.entityId" :entity-id="model.config.entityId" init-tab="formDesign"></entity-design-dialog>
  </div>
  <div v-else class="w-full h-[500px] flex justify-center bg-[#fff] items-center">
    无法加载模型列表表单:{{ entity }}@{{ dataFormFlag }}
  </div>
</template>

<style scoped>

</style>