import type { RouteRecordRaw } from 'vue-router'
const routers: RouteRecordRaw = {
  path: '/admin/shp-product',
  name:'AdminShpProduct',
  meta: {
    title: '商品管理'
  },
  children: [
    {
      path: 'list',
      name: 'AdminShpProductList',
      meta: {
        title: '商品列表'
      },
      component: ()=>{return import("@/views/admin/shp-product/ShpProductList.vue")}
    },
    {
      path: 'detail',
      name: 'AdminShpProductDetail',
      meta: {
        title: '商品详情'
      },
      component: ()=>{return import("@/views/admin/shp-product/ShpProductDetail.vue")}
    }
  ]
}
export default routers
