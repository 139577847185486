import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import $http from 'devcude-common/src/utils/DcHttp'
import $api from '@/config/api'
import qs from 'qs'

export const useSystemStore = defineStore('system', () => {
  const showDcshopChooseBox = ref(false)
  const currentSystem = ref()
  const  userinfo = ref({name:'DevCude'});

  async function configUserinfo(data){
      userinfo.value = data
  }

  async function changeSystem(flag) {
    await $http.post($api.shpAdminAuth.changeSystem, qs.stringify({ flag: flag }))
    localStorage.setItem('DCSHP_SYSTEM_FLAG', flag)
    window.location.href = '/'
  }

  return { showDcshopChooseBox, currentSystem, changeSystem,configUserinfo,userinfo }
})
