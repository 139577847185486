<script setup lang="ts">
import $api from '../config/api'
import $http from 'devcude-common/src/utils/DcHttp'
import { computed, nextTick, onMounted, ref, toRef, toRefs, watch } from 'vue'
import _ from 'lodash'
import qs from 'qs'
import { DcUtils } from 'devcude-common/src/utils/DcUtils'
import { useRoute } from 'vue-router'
import ShpAgentSettleOrderTable from '@/components/ShpAgentSettleOrderTable.vue'

const route = useRoute()
const props = defineProps({
  mode: {
    type: String,
    default() {
      return 'COMMON' +
        ''
    }
  },
  excludeOrderIds: {
    type: Array,
    default: () => {
      return []
    }
  },
  showSearchForm: {
    type: Boolean,
    default: true
  },
  onPage: {
    type: Boolean,
    default: true
  },
  searchQuery: {
    type: Object,
    default: () => {
      return {
        number: '',
        recipient: '',
        recipientPhone: '',
        status: '',
        type: '',
        createTime: ''
      }
    }
  }
})

let pagerKey = 'pager' + '_' + route.fullPath
const pager = ref({
  onPage: props.onPage,
  pageIndex: 1,
  pageSize: 10,
  itemCount: 0
})

const orderTable = ref()
const items = ref([])
const selection = defineModel()
const LIST_URL = $api.shpOrder.list
const DELETE_URL = $api.shpOrder.delete
const BATCH_DELETE_URL = $api.shpOrder.batchDelete
const searchQueryCond = {
  number: 'LIKE',
  createTime: 'BT'
}


// 检索参数开始
const searchQuery = Object.assign({}, props.searchQuery)

const sqy = ref(Object.assign(searchQuery))
const orderTime = ref([])

if (sqy.value.createTime) {
  orderTime.value = sqy.value.createTime.split('@')
}
watch(orderTime, () => {
  if (orderTime.value?.length) {
    sqy.value.createTime = `${orderTime.value[0]}@${orderTime.value[1]}`
  } else {
    sqy.value.createTime = ''
  }
})


const dynamicQuery = computed(() => {
  let p = {}
  for (let key in sqy.value) {
    if (sqy.value[key]) {
      if (searchQueryCond[key]) {
        p[`${key}@${searchQueryCond[key]}`] = sqy.value[key]
      } else {
        p[`${key}`] = sqy.value[key]
      }
    }
  }
  return p
})

async function handleLoadData() {
  let query = { excludeOrderIds: props.excludeOrderIds ? props.excludeOrderIds.join(',') : [] }

  let resp = await $http.post(LIST_URL, qs.stringify(Object.assign(query, pager.value, { conds: DcUtils.param2DynamicCondQuery(dynamicQuery.value) })))
  items.value = resp.data.items
  _.assign(pager.value, _.pick(resp.data.pager, _.keys(pager.value)))
}

async function handleSelectionChange(e: any) {
  selection.value = e
}


async function handleRestSearch() {
  sqy.value = Object.assign({}, searchQuery)
  orderTime.value = []
  pager.value.pageIndex = 1
  handleLoadData()
}


onMounted(async () => {
  await handleLoadData()
})

</script>

<template>
  <div class="w-full  flex flex-col overflow-hidden">

    <el-form v-if="showSearchForm" :inline="true" class="mb-[6px]">

      <el-form-item label="订单编号">
        <el-input v-model="sqy.number" placeholder="订单编号" clearable />
      </el-form-item>


      <el-form-item label="收货人">
        <el-input v-model="sqy.recipient" placeholder="收货人" clearable />
      </el-form-item>

      <el-form-item label="收货人电话">
        <el-input v-model="sqy.recipientPhone" placeholder="收货人电话" clearable />
      </el-form-item>

      <el-form-item label="下单时间">
        <el-date-picker
          v-model="orderTime"
          value-format="YYYY-MM-DD"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-button @click="handleLoadData" type="primary">查询</el-button>
        <el-button @click="handleRestSearch">重置</el-button>
      </el-form-item>
    </el-form>


    <ShpAgentSettleOrderTable ref="orderTable" v-model="items" @selectionChange="handleSelectionChange"
                              :show-selection="true"></ShpAgentSettleOrderTable>

    <el-pagination v-if="onPage" size="size" class="w-full flex justify-center mt-[24px]" :background="true"
                   @size-change="handleLoadData"
                   @change="handleLoadData"
                   v-model:current-page="pager.pageIndex"
                   v-model:page-size="pager.pageSize"
                   layout="total, sizes, prev, pager, next, jumper" :total="pager.itemCount" />

  </div>


</template>

<style scoped>

</style>