<script setup lang="ts">

import DynamicTree from '../form/DynamicTree.vue'
import DynamicCheckbox from '../form/DynamicCheckbox.vue'
import type PermsModule from '../../../data-entity/PermsModule'
import { computed, ref, watch } from 'vue'
import { DcUtils } from 'devcude-common/src/utils/DcUtils'

const props = defineProps({
  modelValue:{
    default(){
      return []
    }
  }
})
const v = ref([]);

const emits = defineEmits(['update:modelValue'])
const current = ref<PermsModule>()
const queryParams = computed(() => {

  let params = {};

  if(current.value){
    params['conds'] = DcUtils.param2DynamicCondQuery({moduleId:current.value.id});
  }

  return params;
})

function handleCurrentChange(data, node) {
  current.value = data;
}
watch(()=>props.modelValue,()=>{
  v.value = props.modelValue;
},{deep:true,immediate:true})

watch(v,()=>{
  emits('update:modelValue',v.value)
},{immediate:true,deep:true})
</script>

<template>
  <div class="action-alloc flex justify-between min-w-[800px]">
    <div class="w-[200px] border p-[12px] mr-[24px]">
      <dynamic-tree @current-change="handleCurrentChange" entity="DC_PERMS_MODULE"></dynamic-tree>
    </div>
    <dynamic-checkbox v-model="v" class="flex-1" :query-params="queryParams" entity="DC_RULE"></dynamic-checkbox>
  </div>
</template>

<style scoped>

</style>