<script setup lang="ts">
import DataFormFieldEditor from './DataFormFieldEditor.vue'

const props = defineProps(['entityObj'])
import { computed, ref } from 'vue'
import CommonList from '../../../../src/components/dynamic-form/CommonList.vue'
import DataFormCheckEditor from './DataFormCheckEditor.vue'
import { DcUtils } from 'devcude-common/src/utils/DcUtils'
import type DataForm from '../../../../data-entity/DataForm'

const currentRow = ref<DataForm>()
const activeTab = ref('field')

function handleCurrentRowChange(row) {
  currentRow.value = row
}


const savePrams = computed(() => {
  return { entityFlag: props.entityObj.flag, entityId: props.entityObj.id }
})
const queryParams = computed(() => {
  return { conds: DcUtils.param2DynamicCondQuery({ entityFlag: props.entityObj.flag, entityId: props.entityObj.id }) }
})
const fieldQueryParams = computed(() => {
  return {
    conds: DcUtils.param2DynamicCondQuery({
      entityFlag: props.entityObj.flag,
      dataFormId: currentRow.value?.id,
      dataFormFlag: currentRow.value?.flag
    })
  }
})
const fieldSaveParams = computed(() => {
  return { entityFlag: props.entityObj.flag, dataFormFlag: currentRow.value?.flag, dataFormId: currentRow.value?.id }
})


</script>

<template>
  <el-row :gutter="20">
    <el-col :span="10">
      <common-list :query-params="queryParams" :save-params="savePrams" @current-row-change="handleCurrentRowChange"
                   entity="DC_DATA_FORM"></common-list>
    </el-col>
    <el-col v-if="currentRow" :span="14">
      <el-descriptions class="mb-[12px]" border>
        <el-descriptions-item label="表单名称">{{ currentRow.name }}</el-descriptions-item>
        <el-descriptions-item label="表单标识">{{ currentRow.flag }}</el-descriptions-item>
      </el-descriptions>
      <el-tabs v-model="activeTab" type="border-card">
        <el-tab-pane name="field" label="表单字段设计">
          <data-form-field-editor v-if="activeTab === 'field'" :query-params="fieldQueryParams" :entity-obj="entityObj"
                                  :save-params="fieldSaveParams"></data-form-field-editor>
        </el-tab-pane>
        <el-tab-pane name="rule" label="表单校验规则">
          <data-form-check-editor v-if="activeTab === 'rule'" :query-params="fieldQueryParams" :entity-obj="entityObj"
                                  :save-params="fieldSaveParams"></data-form-check-editor>
        </el-tab-pane>
      </el-tabs>

    </el-col>
  </el-row>
</template>

<style scoped>

</style>