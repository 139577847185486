<script setup>
 import { getCurrentInstance } from 'vue'
 const {proxy} = getCurrentInstance();
 console.log("RouteTest",proxy.$router)
</script>

<template>
  <div>
    {{$route}}
  </div>
</template>

<style scoped>

</style>