import type { RouteRecordRaw } from 'vue-router'
const entity = "DC_FORM_COMP"
const routers: RouteRecordRaw = {
  path: '/from-comp',
  name:'form',
  meta: {
    title: '表单组件管理'
  },
  children: [
    {
      path: 'list',
      name: 'from-comp-list',
      meta: {
        title: '表单组件列表'
      },
      props:{
        entity:entity,
      },
      component: ()=>{return import("../../views/dynamic-form/DynamicListView.vue")}
    },
  ]
}
export default routers
