<script setup lang="ts">
  const v = defineModel()
  const props = defineProps(['defShow'])
</script>

<template>
  {{v ? v : defShow}}
</template>

<style scoped>

</style>