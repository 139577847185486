<script setup lang="ts">
import { ref, toRef, watch } from 'vue'
import { DcUtils } from 'devcude-common/src/utils/DcUtils'
import DcDateFormat from 'dc-comp-sdk/src/components/form/DcDateFormat.vue'

const props = defineProps(['data', 'tpl', 'logo'])
const printTime = ref(new Date())
function qsParse(qsString) {
  return DcUtils.qsParse(qsString)
}
</script>

<template>
  <div :class="tpl">
    <div id="shp-delivery-order-pint-tpl" class="shp-delivery-order-pint-tpl p-[20px] bg-[#ffffff]">
      <div>
        <div class="card-title">采购信息</div>
        <table class="w-full">
          <tr>
            <td class="td-hd r-label">标题</td>
            <td>{{ data.name }}</td>
            <td class="td-hd r-label">采购日期</td>
            <td>{{ data.purchaseDate }}</td>
          </tr>
          <tr>
            <td class="td-hd r-label">打印时间</td>
            <td>
              <DcDateFormat v-model="printTime" format="YYYY-MM-DD HH:mm"></DcDateFormat>
            </td>
            <td class="td-hd r-label">商品数量</td>
            <td>{{ data.items.length }}</td>
          </tr>
        </table>
      </div>

      <div class="mt-[20px]">
        <div class="card-title">商品明细</div>
        <table class="w-full">
          <thead class="th-bg text-[#ffffff]" style="page-break-inside: avoid">
            <tr>
              <td>产品名称</td>
              <td>规格</td>
              <td>数量</td>
              <td>单位</td>
              <td>供应商</td>
              <td>生成时间</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in data.items" :key="i" style="page-break-inside: avoid">
              <td>{{ item.name }}</td>
              <td>
                <template v-for="(item, i) in qsParse(item.skuCombo)" :key="i">
                  <span class="mr-[12px]"> {{ item.name }} : {{ item.value }} </span>
                </template>
                <template v-if="!qsParse(item.skuCombo).length"> 主规格</template>
              </td>
              <td>{{item.qty}}</td>
              <td>{{item.unit}}</td>
              <td>{{item.shpSupplier}}</td>
              <td>
                <DcDateFormat v-model="item.createTime" format="YYYY-MM-DD HH:mm"></DcDateFormat>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.rrs {
  $theme: rrs;
  @import '@/assets/print-tpl';
}

.jsh {
  $theme: jsh;
  @import '@/assets/print-tpl';
}

.lb {
  $theme: lb;
  @import '@/assets/print-tpl';
}

.tl {
  $theme: tl;
  @import '@/assets/print-tpl';
}

.sy {
  $theme: sy;
  @import '@/assets/print-tpl';
}

.ttt {
  $theme: ttt;
  @import '@/assets/print-tpl';
}
</style>
