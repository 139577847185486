<script setup lang="ts">
import CommonList from '../../../../src/components/dynamic-form/CommonList.vue'
import TableListForm from '../../../../src/components/dynamic-form/TableListForm.vue'
import { computed, ref } from 'vue'
import DynamicListDialog from '../../../../src/components/form/DynamicListDialog.vue'
import { DcUtils } from 'devcude-common/src/utils/DcUtils'

const props = defineProps(['queryParams','saveParams','entityObj'])


const showDialog = ref(false)
const form = ref()

function handleConfirm(items: []) {

  items.forEach((item) => {
    let field = {}
    field.name = item.name
    field.dataEntityFieldFlag = item.flag
    field.type = item.type
    field.cond = 'eq'
    field.defaultValue = item.defaultValue
    field.sortNum = 0
    form.value.handleAdd(field)
  })
  showDialog.value = false
}
const dataEntityQueryParams = computed(()=>{return {conds:DcUtils.param2DynamicCondQuery({entityId:props.entityObj?.id,entityFlag:props.entityObj?.flag})}})
</script>

<template>
  <div>
    <dynamic-list-dialog @confirm="handleConfirm" @close="showDialog=false" @cancel="showDialog=false" :show="showDialog"
                         :query-params="dataEntityQueryParams" entity="DC_DATA_ENTITY_FIELD"></dynamic-list-dialog>
    <div class="mb-[12px]">
      <el-button @click="showDialog=true" type="primary">批量导入</el-button>
    </div>
    <div class="flex flex-col w-full">
      <table-list-form ref="form" :query-params="props.queryParams" :save-params="props.saveParams"
                       entity="DC_DATA_LIST_SEARCH_FIELD"></table-list-form>
    </div>
  </div>
</template>

<style scoped>

</style>