<script setup lang="ts">

import { ref } from 'vue'

const props = defineProps(['entityId','initTab'])
const show = ref(false)
const emit = defineEmits(['confirm', 'cancel'])
import CommonForm from '../dynamic-form/CommonForm.vue'
import DataEntityDesign from './data-entity-designer/DataEntityDesign.vue'



function handleFinish() {
  emit('confirm')
}




</script>

<template>
  <div class="flex flex-row-reverse mt-[12px]">
    <el-button @click="show=true" size="small" type="danger">设置</el-button>
  </div>
  <el-dialog
    :fullscreen="true"
    @close="show=false"
    :model-value="show"
    :center="true"
  >
   <data-entity-design :entity-id="props.entityId" :init-tab="initTab"></data-entity-design>
  </el-dialog>
</template>

<style scoped>


</style>