import type { RouteRecordRaw } from 'vue-router'
const routers: RouteRecordRaw = {
  path: '/dc-shop',
  name:'DcShop',
  meta: {
    title: '商城'
  },
  children: [
    {
      path: 'list',
      name: 'DcShopList',
      meta: {
        title: '商城列表'
      },
      component: ()=>{return import("@/views/dc-shop/DcShopList.vue")}
    },
    {
      path: 'add',
      name: 'DcShopAdd',
      meta: {
        title: '商城新增'
      },
      component: ()=>{return import("@/views/dc-shop/DcShopAdd.vue")}
    },
    {
      path: 'edit',
      name: 'DcShopEdit',
      meta: {
        title: '商城编辑'
      },
      component: ()=>{return import("@/views/dc-shop/DcShopEdit.vue")}
    }
  ]
}
export default routers
