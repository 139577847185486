import type { RouteRecordRaw } from 'vue-router'
const entity = "DC_PERMS_MODULE"
const routers: RouteRecordRaw = {
  path: '/perms-module',
  name:'perms-module',
  meta: {
    title: '权限模块'
  },
  children: [
    {
      path: 'list',
      name: 'perms-module-list',
      meta: {
        title: '权限模块列表'
      },
      props:{
        entity:entity,
      },
      component: ()=>{return import("../../views/dynamic-form/DynamicTreeListView.vue")}
    }
  ]
}
export default routers
