<script setup lang="ts">
import { ref, watch } from 'vue'
import { DcUtils } from 'devcude-common/src/utils/DcUtils'
import DynamicFormUtils from 'devcude-common/src/utils/DynamicFormUtils'

const props = defineProps({
  showCheckBox: {
    type: Boolean,
    default: false
  },
  entity: {
    type: String,
    default: ''
  },
  queryParams: {
    type: Object,
    default: () => {
      return null
    }
  },
  primaryKey: {
    default: 'id'
  },
  parentKey: {
    default: 'parentId'
  },
  labelKey: {
    default: 'name'
  },
  modelValue:{
    type:Array,
    default(){
      return []
    }
  }
})

const emits = defineEmits(['update:modelValue'])

const items = ref<Array<Object>>([])
const v = ref({});

async function initData() {

  const model = await DynamicFormUtils.getListModel(props.entity)
  if (model) {
    let resp = await model.dataModel.list(props.queryParams, { onPage: false })
    items.value = resp.data.items
    items.value.forEach((e)=>{
      v[e[props.labelKey]] = false;
    })
  }

}

watch(() => props.queryParams, () => {
  initData()
}, { deep: true })



initData()

function handleChange(e,item){
  let v = Object.assign([],props.modelValue);
  if(e){
    if(v.indexOf(item[props.primaryKey]) == -1){
       v.push(item[props.primaryKey])
    }
  }else{
    if(v.indexOf(item[props.primaryKey]) != -1){
      v.splice(v.indexOf(item[props.primaryKey]),1);
    }
  }
  emits('update:modelValue',v)
}


</script>

<template>
    <div class="dynamic-check-box">
      <el-checkbox v-for="item in items" :key="item[primaryKey]" @change="handleChange($event,item)"  :label="item[labelKey]" :checked="modelValue.indexOf(item[primaryKey]) != -1" border class="!mr-[16px]" />
    </div>
</template>

<style scoped>

</style>