<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import $http from 'devcude-common/src/utils/DcHttp'
import $api from '../../config/api'
import qs from 'qs'

const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  },
  multiple: {
    type: Boolean,
    default: false
  },
  pid: {
    type: String,
    default: ''
  }
})

const emit = defineEmits(['update:modelValue'])

const v = ref()


const data = ref<Array<any>>([])
let model = ref()

async function initData() {
  let resp = await $http.post($api.areaCompCommon.areaData, qs.stringify({ pid: props.pid }))
  data.value = resp.data.item
}

watch(() => props.modelValue, () => {
  v.value = props.multiple ? props.modelValue.split(',') : props.modelValue
}, { immediate: true })

watch(() => props.queryParams, () => {
  initData()
}, { deep: true })

watch(v, () => {
  emit('update:modelValue', props.multiple ? v.value.join(',') : v.value)
})


initData()


</script>

<template>
  <el-cascader v-model="v" :options="data" :props="{label:'name',value:'id',emitPath:false,multiple:props.multiple}"
               filterable />
</template>

<style scoped>

</style>