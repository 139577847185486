<script setup lang="ts">
import { getCurrentInstance, ref } from 'vue'

const props = defineProps(['entity','flag'])
import CommonTreeList from '../../dynamic-form/CommonTreeList.vue'

const {proxy} = getCurrentInstance();
const router = proxy.$dcRouter
const route = proxy.$dcRoute
let entity = route.value.params._entity?.toString()
if (!entity) {
  entity = props.entity
}

let flag = route.value.params._flag?.toString()
if (!flag) {
  flag = props.flag
}




</script>

<template>
  <div class="p-[24px] bg-[#fff]">
    <common-tree-list   :entity="entity" :data-list-flag="flag"></common-tree-list>
  </div>
</template>

<style scoped>

</style>