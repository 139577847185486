<script setup lang="ts">
import TableListForm from '../../../../src/components/dynamic-form/TableListForm.vue'
const props = defineProps(['queryParams','saveParams','entityObj'])

</script>

<template>
  <table-list-form entity="DC_DATA_LIST_TABLE_OP_BTN" :query-params="props.queryParams" :save-params="props.saveParams"></table-list-form>
</template>

<style scoped>

</style>