import type { RouteRecordRaw } from 'vue-router'

const routers: RouteRecordRaw = {
  path: '/admin/shp-user',
  name: 'AdminShpUser',
  meta: {
    title: '用户管理'
  },
  children: [
    {
      path: 'list',
      name: 'AdminShpUserList',
      meta: {
        title: '用户列表'
      },
      component: () => {
        return import('@/views/admin/shp-user/ShpUserList.vue')
      }
    }
  ]
}
export default routers
