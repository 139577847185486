<script setup lang="ts">

import DynamicFormUtils from 'devcude-common/src/utils/DynamicFormUtils'

const props = defineProps(['entity', 'flag'])
import DcDynamicComp from '../../form/DcDynamicComp.vue'
import CommonForm from '../../dynamic-form/CommonForm.vue'
import { getCurrentInstance, ref } from 'vue'

const {proxy} = getCurrentInstance();
const router = proxy.$dcRouter
const route = proxy.$dcRoute
let _entity = route.value.params._entity?.toString()
if (!_entity) {
  _entity = props.entity
}

let _flag = route.value.params._flag?.toString()
if (!_flag) {
  _flag = props.flag
}
const model = ref()

async function loadData() {
  model.value = await DynamicFormUtils.getFormModel(_entity, _flag)
}

loadData()

</script>

<template>
  <common-form v-if="!model?.config.routeComp" :save-params="route.value.query" :entity="entity" :data-form-flag="flag"
               :init-data="route.value.query._action === 'edit'"></common-form>
  <DcDynamicComp v-else :is="model?.config.routeComp"></DcDynamicComp>
</template>

<style scoped>

</style>