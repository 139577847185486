<template>
  <DcLayout :logo-url="logoUrl" :user-name="userName" v-if="loading" :config="config">
    <template v-slot:header-left>
      <slot name="header-left"></slot>
    </template>
    <router-view :key="$route.fullPath"></router-view>
  </DcLayout>
</template>
<script lang="ts" setup>
import { getCurrentInstance, ref } from 'vue'
import LayoutConfig from 'devcude-ui/src/types/models/layout/LayoutConfig'
import DropdownItemConfig from 'devcude-ui/src/types/models/layout/DropdownItemConfig'
import _ from 'lodash'
import $http from 'devcude-common/src/utils/DcHttp'
import $api from '../config/api'
import DropdownConfig from 'devcude-ui/src/types/models/layout/DropdownConfig'
import DcLayout from 'devcude-ui/src/components/layout/DcLayout.vue'
import  qs from 'qs'
const { proxy } = getCurrentInstance()
const router = proxy.$dcRouter
const route = proxy.$dcRoute
const config = ref(new LayoutConfig())
const userDropdownConfig = new DropdownConfig()
if (route?.value.query?.$menuFlag) {
  localStorage.setItem('MENU_FLAG', route.value.query.$menuFlag)
}
let flag = localStorage.getItem('MENU_FLAG')
const loading = ref(false)

const props = defineProps({
  userName: {
    type: String,
    default: 'DevCude'
  },
  logoUrl: {
    type: String,
    default: ''
  }
})

async function init() {
  let resp = await $http.post($api.authCompCommon.userinfo);

  $http.post($api.authCompCommon.userMenus,qs.stringify({flag:flag})).then((resp) => {
    config.value.menuConfig = resp.data.items
    loading.value = true
  })

}

init()

function handleLogout() {
  $http.post($api.authCompCommon.logout).then((resp) => {
    router.push('/login')
  })
}

userDropdownConfig.items = [
  // _.defaultsDeep({ title: '个人中心', icon: 'user' }, new DropdownItemConfig()),
  // _.defaultsDeep({ title: '用户设置', icon: 'setting' }, new DropdownItemConfig()),
  _.defaultsDeep({
    title: '退出',
    icon: 'switch-button',
    // divided: true,
    handleClick: handleLogout
  })
]
const langDropdownConfig = new DropdownConfig()
langDropdownConfig.items = [
  _.defaultsDeep({ title: '简体中文' }, new DropdownItemConfig()),
  _.defaultsDeep({ title: '繁體中文' }, new DropdownItemConfig()),
  _.defaultsDeep({ title: 'English' }, new DropdownItemConfig())
]
config.value.userDropdownConfig = userDropdownConfig

config.value.langDropdownConfig = langDropdownConfig

</script>
