<script setup lang="ts">
import { toRef } from 'vue'
const props = defineProps(['data','printTime','tpl','logo','order'])
const item = toRef(props.data)

if(item.value.orderInfo.number.length <= 11){
  item.value.orderInfo.simpleNum = (item.value.orderInfo.number.slice(0,item.value.orderInfo.number.length - 8) +  item.value.orderInfo.number.slice(-4));
}else{
  item.value.orderInfo.simpleNum = (item.value.orderInfo.number.slice(0,item.value.orderInfo.number.length - 12) +  item.value.orderInfo.number.slice(-6));

}


</script>

<template>
  <div>
    <div class="sticker-print-tpl">
      <div class="number"><span class="label">單號:</span><span>{{item.orderInfo.simpleNum}}</span></div>
      <div class="name" v-if="item.pkgInfo"><span class="label">套餐:</span><span>{{item.pkgInfo.name}} * {{item.pkgInfo.itemQty}}</span></div>
      <template v-if="item.skuRefProdName">
        <div class="name"><span class="label">貨品:</span><span>{{item.skuRefProdName}}</span></div>
        <div v-if="item.skuCombo" class="name"><span class="label">規格:</span><span>{{item.skuCombo}}</span></div>
      </template>
      <template v-else>
        <div class="name"><span class="label">貨品:</span><span>{{item.diyName ? item.diyName : item.name}}</span></div>
        <div v-if="item.skuCombo" class="name"><span class="label">規格:</span><span>{{item.skuCombo}}</span></div>
      </template>
      <div class="amount"><span><span class="label">份量:</span><span class="fl">{{item.itemQty}}</span></span><span><span v-if="item.split_i" class="label">拆單:</span><span>{{item.split_i}} / {{item.split_o}}</span></span></div>
      <div class="last"><div>原始份量：{{item.itemQty}} ({{item.unit}})&nbsp;&nbsp;&nbsp;&nbsp;總份量：{{item.itemQty}} ({{item.unit}})</div><div>{{parseInt(item.print_i)}} / {{item.print_sum}}</div></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.sticker-print-tpl {
  width: 600px;
  padding-top: 24px;
  box-sizing: border-box;
  page-break-after: always;
}

.number {
  font-size: 48px;
  font-weight: 800;
  text-align: center;
}

.name {
  margin-top: 24px;
  font-size: 46px;
  font-weight: 800;
  line-height: 32px;
  min-height: 64px;
}

.amount {
  margin-top: 12px;
  font-size: 36px;
  display: flex;
  justify-content: space-between;
  font-weight: 800;
  white-space: nowrap;
}

.label {
  padding-right: 12px;
  display: inline-block;
  white-space: nowrap;
}

.last{
  font-size: 14px;
  display: flex;
  border-top: 4px solid #000000;
  margin-top: 24px;
  padding-top: 12px;
  font-weight: bold;
  justify-content: space-between;
  white-space: nowrap;
}





</style>
