<script setup lang="ts">
import { ref, watch } from 'vue'
import qs from 'qs'
import $http from 'devcude-common/src/utils/DcHttp'
import $api from '../config/api'
import ShpAgentProdSkuEditor from '@/components/ShpAgentProdSkuEditor.vue'

const v = ref(false)
const emit = defineEmits(['finish'])
const initData = ref({})
const fd = ref({})
const mainSku = ref({ agentPrice: '', agentSettlePrice: '', skuCombo: 'MAIN' })

async function show(data: { productId, shpAgentId }) {
  initData.value = data
  handleLoadData()
  v.value = true
}

let srcFd = null

async function handleLoadData() {
  let resp = await $http.post($api.shpProduct.detail, qs.stringify({
    id: initData.value.productId,
    shpAgentId: initData.value.shpAgentId
  }))
  srcFd = resp.data.item
  handleReset()
}

async function handleSave() {
  const d = { shpAgentId: initData.value.agentId, productId: initData.value.productId, items: [] }

  if (fd.value.multiSku === 'Y') {
    for (let key in fd.value.skuInfos) {
      d.items.push({
        price: fd.value.skuInfos[key].agentPrice,
        skuCombo: fd.value.skuInfos[key].skuCombo,
        settlePrice: fd.value.skuInfos[key].agentSettlePrice
      })
    }
  } else {
    d.items.push({
      price: mainSku.value.agentPrice,
      skuCombo: mainSku.value.skuCombo,
      settlePrice: mainSku.value.agentSettlePrice
    })
  }


  await $http.post($api.shpAgentPrice.settingAgentProdPrice, d, { showTips: true })
  v.value = false
}

async function handleReset() {
  fd.value = JSON.parse(JSON.stringify(srcFd))
  mainSku.value = fd.value.mainSku

}


watch(fd, () => {
  console.log(fd.value.skuInfos)
}, { deep: true })

defineExpose({ show })

</script>

<template>
  <el-dialog v-model="v" @close="handleClose" width="1200px">
    <el-form ref="formInst" :model="fd" :rules="rules" size="large" label-width="auto" class="bg-[#fff] pt-[12px]">

      <el-form-item v-if="fd.multiSku === 'Y'">
        <ShpAgentProdSkuEditor :sku-info="fd.skuInfos" :sku-items="fd.skuItems"></ShpAgentProdSkuEditor>
      </el-form-item>

      <template v-else-if="fd.mainSku">
        <el-form-item label="原价" prop="name">
          <el-input placeholder="原价" v-model="fd.mainSku.origPrice" disabled></el-input>
        </el-form-item>
        <el-form-item label="售价" prop="name">
          <el-input placeholder="售价" v-model="fd.mainSku.price" disabled></el-input>
        </el-form-item>
        <el-form-item label="成本价" prop="name">
          <el-input placeholder="成本价" v-model="fd.mainSku.costPrice" disabled></el-input>
        </el-form-item>
        <el-form-item label="渠道售价" prop="name">
          <el-input placeholder="渠道售价" v-model="mainSku.agentPrice"></el-input>
        </el-form-item>
        <el-form-item label="渠道结算价" prop="name">
          <el-input placeholder="渠道结算价" v-model="mainSku.agentSettlePrice"></el-input>
        </el-form-item>
      </template>


      <div class="flex justify-center">
        <el-button @click="handleSave" type="primary" size="large">保存</el-button>
        <el-button @click="handleReset" size="large">重置</el-button>
      </div>
    </el-form>
  </el-dialog>
</template>

<style scoped>

</style>