import type { RouteRecordRaw } from 'vue-router'
const routers: RouteRecordRaw = {
  path: '/shp-unit',
  name:'ShpUnit',
  meta: {
    title: '单位管理'
  },
  children: [
    {
      path: 'list',
      name: 'ShpUnitList',
      meta: {
        title: '单位列表'
      },
      component: ()=>{return import("@/views/shp-unit/ShpUnitList.vue")}
    },
    {
      path: 'add',
      name: 'ShpUnitAdd',
      meta: {
        title: '单位新增'
      },
      component: ()=>{return import("@/views/shp-unit/ShpUnitAdd.vue")}
    },
    {
      path: 'edit',
      name: 'ShpUnitEdit',
      meta: {
        title: '单位编辑'
      },
      component: ()=>{return import("@/views/shp-unit/ShpUnitEdit.vue")}
    }
  ]
}
export default routers
