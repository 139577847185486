<script setup lang="ts">
import { ref, defineModel, watch } from 'vue'
import $http from 'devcude-common/src/utils/DcHttp'
import $api from '@/config/api'
import qs from 'qs'

const props = defineProps(['purchaseReportId'])
const v = defineModel()
const items = ref([])

async function init() {
  let resp = await $http.post($api.purchaseReport.listShpSuppliers, qs.stringify({
    onPage: false,
    purchaseReportId: props.purchaseReportId
  }))
  items.value = resp.data.items
}

init()


</script>

<template>
  <el-select default-first-option class="!min-w-[160px]" v-model="v">
    <el-option
      v-for="item in items"
      :key="item"
      :label="item"
      :value="item as string"
    />
  </el-select>
</template>

<style scoped>

</style>