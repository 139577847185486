<script setup lang="ts">
import { getCurrentInstance, ref } from 'vue'
import DataEntityApi from '../../../api/DataEntityApi'
import DataListEditor from './DataListEditor.vue'
import DataFormEditor from './DataFormEditor.vue'
import DataEntityEditor from './DataEntityEditor.vue'

const props = defineProps(['entityId', 'initTab'])
const {proxy} = getCurrentInstance();
const router = proxy.$dcRouter
const route = proxy.$dcRoute
const dataEntity = ref()
const actTab = ref(props.initTab ? props.initTab : 'entityDesign')

async function handleInitData() {
  let resp = await DataEntityApi.detail(Object.assign({}, { id: props.entityId ? props.entityId : route.value.query.id }))
  if (resp.status == 200) {
    dataEntity.value = resp.data.item
  }
}

handleInitData()


</script>

<template>
  <div v-if="dataEntity" class="flex-1 h-full flex flex-col justify-between">


    <el-form class="bg-[#fff] pl-[24px] pr-[24px] pt-[24px] " :inline="true">
      <el-form-item label="实体名称">
        {{ dataEntity.name }}
      </el-form-item>
      <el-form-item label="实体标识">
        {{ dataEntity.flag }}
      </el-form-item>
      <el-form-item label="关联数据源">
        {{ dataEntity.dsFlag }}
      </el-form-item>
      <el-form-item label="关联表">
        {{ dataEntity.tableName }}
      </el-form-item>
    </el-form>

    <el-tabs v-model="actTab" class="bg-[#ffffff] pl-[24px] pr-[24px] pb-[24px] flex-1">
      <el-tab-pane name="entityDesign" label="实体设计" class="flex-1">
        <data-entity-editor v-if="actTab === 'entityDesign' " :entityObj="dataEntity"></data-entity-editor>
      </el-tab-pane>
      <el-tab-pane name="listDesign" label="列表设计 ">
        <data-list-editor v-if="actTab === 'listDesign' " :entityObj="dataEntity"></data-list-editor>
      </el-tab-pane>
      <el-tab-pane name="formDesign" label="表单设计">
        <data-form-editor v-if="actTab === 'formDesign' " :entityObj="dataEntity"></data-form-editor>
      </el-tab-pane>
    </el-tabs>
  </div>


</template>

<style scoped>

</style>