import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const modules = import.meta.glob('./modules/*.ts', { eager: true })

const routes = {
  path: '/',
  name: 'home',
  meta: {
    title: '首页'
  },
  component: HomeView,
  children: [
    {
      name: 'DemoList',
      path: '/',
      meta: {
        title: 'DEMO列表页'
      },
      component: () => {
        return import('../views/DemoListView.vue')
      }
    },
    {
      name: 'MenuConfig',
      path: '/menu-config',
      meta: {
        title: '菜单管理'
      },
      component: () => {
        return import('../views/MenuConfigView.vue')
      }
    },
    {
      name: 'DictConfigView',
      path: '/dict-config',
      meta: {
        title: '字典管理'
      },
      component: () => {
        return import('../views/DictConfigView.vue')
      }
    },
    {
      name: 'RuleConfigView',
      path: '/rule-config',
      meta: {
        title: '权限管理'
      },
      component: () => {
        return import('../views/RuleConfigView.vue')
      }
    },
    {
      name: 'ActionConfigView',
      path: '/action-config',
      meta: {
        title: '功能管理'
      },
      component: () => {
        return import('../views/ActionConfigView.vue')
      }
    },
    {
      name: 'SysSettingView',
      path: '/sys-setting',
      meta: {
        title: '系统配置'
      },
      component: () => {
        return import('../views/SysSettingView.vue')
      }
    },
    {
      name: 'PermAllocView',
      path: '/perm-alloc',
      meta: {
        title: '权限分配'
      },
      component: () => {
        return import('../views/perm-alloc/PermAllocView.vue')
      }
    },
    {
      name: 'TableConfigView',
      path: '/table-config',
      meta: {
        title: '数据表管理'
      },
      component: () => {
        return import('../views/TableConfigView.vue')
      }
    }
  ]
}
for (const path in modules) {
  routes.children.push(modules[path].default)
}



export default [{
  name: 'Login',
  path: '/login',
  meta: {
    title: '登录'
  },
  component: () => {
    return import('../views/LoginView.vue')
  }
},routes]
