<script setup lang="ts">
import $api from '@/config/api'
import $http from 'devcude-common/src/utils/DcHttp'
import { computed, nextTick, onMounted, ref, watch,defineModel} from 'vue'
import _ from 'lodash'
import qs from 'qs'
import DcSinglePicShow from 'dc-comp-sdk/src/components/form/DcSinglePicShow.vue'
const v = defineModel()

const props = defineProps(['orderAmount'])
const pager = ref({
  pageIndex: 1,
  pageSize: 10,
  itemCount: 0,
  onPage: false
})


const shpProdChooseBox = ref()
const items = ref([])
const selection = ref([])
const LIST_URL = $api.bogoProduct.list
const table = ref()


async function handleLoadData() {
  let resp = await $http.post(LIST_URL, qs.stringify(Object.assign({}, pager.value)))
  items.value = resp.data.items
  _.assign(pager.value, _.pick(resp.data.pager, _.keys(pager.value)))
}

async function handleSelectionChange(e: any) {
  selection.value = e
}


function handleCurrentChange(){
  table.value.setCurrentRow(items.value.find(e=>e.id == v.value))
}



onMounted(async () => {
  await handleLoadData()
  nextTick(()=>{
    watch(v,()=>{
      table.value.setCurrentRow(items.value.find(e=>e.id == v.value))
    })
  })
})

</script>

<template>
  <div class="w-full h-full">

    <el-table ref="table" :data="items" @selectionChange="handleSelectionChange" highlight-current-row @current-change="handleCurrentChange"
              :row-key="(row:any)=>{return row.id}" border>
      <el-table-column width="80px" label="图片" prop="pics">
        <template #default="scope">
          <DcSinglePicShow width="32px" height="32px"
                           :model-value="scope.row.product.pics ? scope.row.product.pics.split(',')[0] : ''"></DcSinglePicShow>
        </template>
      </el-table-column>
      <el-table-column label="编号" prop="product.number"></el-table-column>
      <el-table-column label="商品名称" prop="product.name"></el-table-column>
      <el-table-column label="满赠金额" prop="amount"></el-table-column>
      <el-table-column label="操作" align="right">
        <template #default="scope">
          <template v-if="parseFloat(orderAmount) >= parseFloat(scope.row.amount)">
            <el-link v-if="v !== scope.row.id" :underline="false" @click="v = scope.row.id" type="warning">选择
            </el-link>
            <el-link v-else :underline="false" @click="v = ''" type="success">已选择</el-link>
          </template>
          <template v-else>
            <el-link :underline="false" :disabled="false">
              未满足赠送金额(还差{{ (parseFloat(scope.row.amount) - parseFloat(orderAmount)).toFixed(2) }})
            </el-link>
          </template>
        </template>
      </el-table-column>
    </el-table>

  </div>


</template>

<style scoped>

</style>