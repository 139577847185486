<script setup lang="ts">
import { computed, ref } from 'vue'

const props = defineProps(['is', 'compAttr'])


const attrs = computed(() => {
  let attrs = {}

  const defaultProps = typeof props.compAttr === 'object' ? props.compAttr : JSON.parse(props.compAttr ? props.compAttr : '{}')

  if (defaultProps) {
    for (let key in defaultProps) {
      attrs[key] = defaultProps[key]
    }
  }

  return attrs
})


</script>

<template>
  <component v-if="comp" :is="comp['label']" v-bind="attrs"></component>
</template>

<style scoped>

</style>