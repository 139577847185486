import type { RouteRecordRaw } from 'vue-router'
const routers: RouteRecordRaw = {
  path: '/shp-agent',
  name:'ShpAgent',
  meta: {
    title: '渠道商代理管理'
  },
  children: [
    {
      path: 'list',
      name: 'ShpAgentList',
      meta: {
        title: '渠道商代理列表'
      },
      component: ()=>{return import("@/views/shp-agent/ShpAgentList.vue")}
    },
    {
      path: 'shp-agent-price-setting',
      name: 'ShpAgentPriceSetting',
      meta: {
        title: '渠道价格设置'
      },
      component: ()=>{return import("@/views/shp-agent/ShpAgentProductList.vue")}
    },
    {
      path: 'add',
      name: 'ShpAgentAdd',
      meta: {
        title: '渠道商代理新增'
      },
      component: ()=>{return import("@/views/shp-agent/ShpAgentAdd.vue")}
    },
    {
      path: 'edit',
      name: 'ShpAgentEdit',
      meta: {
        title: '渠道商代理编辑'
      },
      component: ()=>{return import("@/views/shp-agent/ShpAgentEdit.vue")}
    }
  ]
}
export default routers
