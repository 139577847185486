<script setup lang="ts">
import DcSinglePicShow from 'dc-comp-sdk/src/components/form/DcSinglePicShow.vue'
import { DcUtils } from 'devcude-common/src/utils/DcUtils'
import { computed } from 'vue'

const props = defineProps({
  limit: {
    type: Number,
    default() {
      return -1
    }
  }
})

const v = defineModel([])

function qsParse(qsString) {
  return DcUtils.qsParse(qsString)
}


</script>

<template>
  <div>
    <div v-for="(item,j) in v" :key="j" class="mb-[12px]">
      <div>
        <div class="flex justify-between">
          <span class="flex-1 flex items-center">
          <el-text size="small" class="!mr-[12px]" line-clamp="1">{{ item.name }}</el-text>
           </span>
          <el-text size="small" >x{{ item.itemQty }}</el-text>
        </div>
        <div>
          <span>
        <template v-for="(ic,i) in qsParse(item.skuCombo)" :key="i">
                      <span class="mr-[12px]">
                          <el-tag size="small">{{ ic.name }} : {{ ic.value }}</el-tag>
                      </span>
          </template>
          <template v-if="!qsParse(item.skuCombo).length">
            <el-tag size="small">主规格</el-tag>
          </template>
        </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
