<script setup lang="ts">

const props = defineProps(['entity','flag'])
import CommonList from '../../dynamic-form/CommonList.vue'
import { getCurrentInstance } from 'vue'
const {proxy} = getCurrentInstance();
const router = proxy.$dcRouter
const route = proxy.$dcRoute
console.log("route",route)
console.log("router",router)
let entity = route.value.params._entity?.toString()
if (!entity) {
  entity = props.entity
}

let flag = route.value.params._flag?.toString()
if (!flag) {
  flag = props.flag
}




</script>

<template>
  <div class="p-[24px] bg-[#fff]">
    <common-list    :entity="entity" :data-list-flag="flag" ></common-list>
  </div>
</template>

<style scoped>

</style>