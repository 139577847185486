<script setup lang="ts">

</script>

<template>
  <div class="p-[24px] bg-[#fff]">
    <slot></slot>
  </div>
</template>

<style scoped>

</style>