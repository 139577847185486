<script setup lang="ts">
import { RouterView } from 'vue-router'
import zhCn from 'element-plus/es/locale/lang/zh-tw'
import { ElNotification } from 'element-plus'

import { computed, ref } from 'vue'
import DcshopChooseBox from '@/components/DcshopChooseBox.vue'
import { useSystemStore } from '@/stores/system'
import loginBg from '@/assets/login_bg.png'
import $http from 'devcude-common/src/utils/DcHttp'
import $api from './config/api'
window['SYS_LOGIN_BG'] = loginBg
const systemStore = useSystemStore()
const showChooseDcShopDialog = computed({
  get: () => systemStore.showDcshopChooseBox,
  set: (value) => {
    systemStore.showDcshopChooseBox = value
  }
})

setInterval(async ()=>{
 const resp = await $http.post($api.shpOrder.waitDealOrderCount);
 if(resp.data.waitDealOrderCount > 0){
   ElNotification({
     title: '待处理订单',
     position: 'bottom-left',
     message: `您有${resp.data.waitDealOrderCount}个订单等待处理`,
     type: 'warning',
   })
 }
},3 * 1000)

</script>

<template>
  <el-config-provider :locale="zhCn">
    <RouterView />
    <DcshopChooseBox v-model="showChooseDcShopDialog"></DcshopChooseBox>
  </el-config-provider>
</template>
