<script setup lang="ts">
import { ref } from 'vue'
import DictCompCommonApi from '../../api/DictCompCommonApi'
import type DictTypeItem from '../../../data-entity/DictTypeItem'
const props = defineProps({dictType:{type:String, default: ''}})
const v = defineModel<String>()
const options = ref<Array<DictTypeItem>>()

async function initData() {

  const cacheKey = ('DC_DICT_SELECT_CACHE')
  if (!window[cacheKey]) {
    window[cacheKey] = DictCompCommonApi.dictData()
  }

  window[cacheKey].then(resp => {
    options.value = resp.data.items.filter(e=>e.dictTypeFlag === props.dictType);
  })

}



initData()

</script>

<template>
  <el-select
    v-model="v"
    class="min-w-[180px]"
    v-if="options"
  >
    <el-option
      v-for="item in options"
      :key="item.dictValue"
      :label="item.name"
      :value="item.dictValue as string"
    />
  </el-select>
</template>

<style scoped>

</style>