<script setup lang="ts">
import { ref, watch } from 'vue'
import $http from 'devcude-common/src/utils/DcHttp'
import $api from '../config/api'
import qs from 'qs'

const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  },
  multiple: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['update:modelValue'])

const v = ref()


const data = ref<Array<any>>([])

async function initData() {
  let resp = await $http.post($api.shpUnit.list,qs.stringify({onPage:false}))
  data.value = resp.data.items
}

watch(() => props.modelValue, () => {

  if (props.modelValue) {
    v.value = props.multiple ? props.modelValue.split(',') : props.modelValue
  } else {
    v.value = props.multiple ? [] : props.modelValue
  }
}, { immediate: true })

watch(v, () => {
  emit('update:modelValue', props.multiple ? v.value.join(',') : v.value)
})


initData()


</script>

<template>
  <el-select
    class="min-w-[180px]"
    v-if="data"
    v-model="v"
    :multiple="multiple"
    filterable
  >
    <el-option
      v-for="item in data"
      :key="item.id"
      :label="item.name"
      :value="item.id"
    />
  </el-select>
</template>

<style scoped>

</style>