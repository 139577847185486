<script setup lang="ts">
import { ref } from 'vue'
import DynamicFormCommonApi from '../../api/DynamicFormCommonApi'
import type FormComp from '../../../data-entity/FormComp'


const options = ref<Array<FormComp>>()

async function initData() {

  const cacheKey = ('DC_FORM_COMP_SELECT')
  if (!window[cacheKey]) {
    window[cacheKey] = DynamicFormCommonApi.formComps();
  }

  window[cacheKey].then(resp => {
    options.value = resp.data.items
  })

}



initData()

</script>

<template>
  <el-select
    v-if="options"
  >
    <el-option
      v-for="item in options"
      :key="item.label + '@' + item.flag"
      :label="item.name"
      :value="item.label + '@' + item.flag"
    />
  </el-select>
</template>

<style scoped>

</style>