import type { RouteRecordRaw } from 'vue-router'
const routers: RouteRecordRaw = {
  path: '/shp-banner',
  name:'ShpBanner',
  meta: {
    title: 'BANNER管理'
  },
  children: [
    {
      path: 'list',
      name: 'ShpBannerList',
      meta: {
        title: 'BANNER列表'
      },
      component: ()=>{return import("@/views/shp-banner/ShpBannerList.vue")}
    },
    {
      path: 'add',
      name: 'ShpBannerAdd',
      meta: {
        title: 'BANNER新增'
      },
      component: ()=>{return import("@/views/shp-banner/ShpBannerAdd.vue")}
    },
    {
      path: 'edit',
      name: 'ShpBannerEdit',
      meta: {
        title: 'BANNER编辑'
      },
      component: ()=>{return import("@/views/shp-banner/ShpBannerEdit.vue")}
    }
  ]
}
export default routers
