import  Pager from '../../models/dynamic-form/Pager'
import  FieldConfig from '../../models/dynamic-form/FieldConfig'
import BaseModel from '../../models/dynamic-form/BaseModel'
import type DataModel from '../../models/dynamic-form/DataModel'
import type BtnConfig from '../../models/dynamic-form/BtnConfig'
import DataListConfig from '../../models/dynamic-form/DataListConfig'

export default class DynamicListModel extends BaseModel{

  config:DataListConfig = new DataListConfig()

  pager?:Pager = new Pager()

  fields:Array<FieldConfig> = []

  searchFields:Array<FieldConfig> = []

  searchConditionForm = {}

  tableItems:[]

  selection:[]

  opBtns:BtnConfig[] = []

  hideBtnGroup:boolean=false
  hidePager:boolean=false
  hideTableOp:boolean=false

  constructor(dataModel: DataModel) {
    super(dataModel)
  }
}