<script setup lang="ts">
import { getCurrentInstance, ref } from 'vue'
import CommonList from '../../components/dynamic-form/CommonList.vue'
const {proxy} = getCurrentInstance();
const router = proxy.$dcRouter
const route = proxy.$dcRoute
const props = defineProps(['entity'])
console.log("route",route)
console.log("router",router)
let modelName = route?.params?.model?.toString()
if (!modelName) {
  modelName = props.entity
}




</script>

<template>
  <div class="p-[24px] bg-[#fff]">
    <CommonList :entity="modelName"></CommonList>
  </div>
</template>

<style scoped>

</style>