import type { RouteRecordRaw } from 'vue-router'
import DynamicListView from "../../views/dynamic-form/DynamicListView.vue"
const routers: RouteRecordRaw = {
  path: '/dict-type',
  name:'dict-type',
  meta: {
    title: '字典管理'
  },
  children: [
    {
      path: 'list',
      name: 'data-type-list',
      meta: {
        title: '字典列表'
      },
      props:{
        entity:'DC_DICT_TYPE',
      },
      component: () => DynamicListView
    }
  ]
}
export default routers
