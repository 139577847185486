<script setup lang="ts">
import ECharts from 'vue-echarts'
import { ref, watch } from 'vue'
import qs from 'qs'
import $http from 'devcude-common/src/utils/DcHttp'
import $api from '../config/api'

const props = defineProps(['type'])
const option = ref({
  legend: {
    data: ['营业额', '订单量']
  },
  tooltip: {
    trigger: 'axis'
  },
  grid: {
    left: '5%',
    right: '5%',
    bottom: '5%'
  },
  xAxis: {
    type: 'category',
    data: []
  },
  yAxis: [
    {
      type: 'value',
      name: '营业额'
    },
    {
      type: 'value',
      name: '订单量',
      position: 'right',
      alignTicks: true,
      offset: 0,
      axisLine: {
        show: true
      },
      axisLabel: {
        formatter: '{value}'
      }
    }
  ],
  series: [
    {
      name: '营业额',
      type: 'line',
      smooth: true,
      data: []
    },
    {
      name: '订单量',
      type: 'line',
      smooth: true,
      data: [],
      yAxisIndex: 1
    }
  ]
})

async function init() {
  let resp = await $http.post($api.shpCommon.saleData, qs.stringify({ type: props.type }))
  let items = resp.data.items
  let seriesData0 = []
  let seriesData1 = []
  let xAxisData = []
  items.forEach((e) => {
    xAxisData.push(e.name)
    seriesData0.push(e.amount)
    seriesData1.push(e.orderCount)
  })
  option.value.xAxis.data = xAxisData
  option.value.series[0].data = seriesData0
  option.value.series[1].data = seriesData1
}

init()

watch(e => props.type, () => {
  init()
})

</script>

<template>
  <ECharts :option="option"></ECharts>
</template>

<style scoped>

</style>